import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Label,
  LabelList,
  Legend,
  isStacked,
} from "recharts";
import {
  customTooltipLayout,
  formatNumberFull,
} from "../../services/UtilsService";
import { _variablesTrafficLight } from "../../styles/_variables";

const McBarChartTrafficLight = ({
  data,
  dataGuide,
  labels,
  height = 600,
  palette = _variablesTrafficLight,
  background = true,
  tooltipMeasureUnit = "",
  marginLeft = false,
  marginRight = false,
}) => {
  const getTrafficLightColor = (value) => {
    if (value <= 5) {
      return _variablesTrafficLight.primaryGreen;
    } else if (value <= 10) {
      return _variablesTrafficLight.primaryYellow;
    } else if (value <= 15) {
      return _variablesTrafficLight.primaryOrange;
    } else if (value <= 50) {
      return _variablesTrafficLight.primaryDarkOrange;
    } else {
      return _variablesTrafficLight.primaryRed;
    }
  };

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          fontSize={10}
        >
          {formatNumberFull(payload.value)} bps
        </text>
      </g>
    );
  };

  const CustomizedAxisTickSmall = ({ x, y, stroke, payload }) => {
    return (
      <g>
        <text x={x} y={y} dy={4} textAnchor="end" fill="#666" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return customTooltipLayout(active, payload, label, tooltipMeasureUnit);
    }

    return null;
  };

  const renderCustomizedLabel = ({ x, y, width, height, value }) => {
    let xPosition = 30 + x + width;

    return (
      <g>
        <text
          x={xPosition > 400 ? 400 : xPosition}
          y={y + height / 2}
          fill="#141413"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {formatNumberFull(value)}
        </text>
      </g>
    );
  };

  return (
    <>
      <ResponsiveContainer width="80%" height={height}>
        <BarChart
          layout="vertical"
          width="100%"
          height="100%"
          data={data}
          margin={{
            top: 0,
            right: marginRight ? 90 : 30,
            left: marginLeft ? 60 : 0,
            bottom: 60,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            ticks={[0, 5, 20, 50]}
            tick={<CustomizedAxisTick />}
            scale="sqrt"
            interval={0}
          >
            {labels ? (
              labels[0] && (
                <Label value={labels[0]} offset={-40} position={"top"} />
              )
            ) : (
              <></>
            )}
          </XAxis>
          <YAxis
            type="category"
            dataKey={dataGuide.y}
            tick={<CustomizedAxisTickSmall />}
          ></YAxis>
          <Tooltip content={<CustomTooltip />} />

          {dataGuide.barsData.map((bar, index) => {
            return (
              <Bar
                dataKey={bar.dataName}
                name={bar.friendlyName}
                fill={_variablesTrafficLight.textBlack}
                background={
                  background &&
                  index === 0 && { fill: _variablesTrafficLight.chartGrey }
                }
                stackId="a"
                key={`bar-${index}-${Math.random()}`}
                animationBegin={1000}
                barSize={30}
              >
                {data.map((entry, index) => (
                  <Cell
                    cursor="pointer"
                    fill={getTrafficLightColor(entry.value)}
                    key={`cell-${index}-${Math.random()}`}
                  />
                ))}
                <LabelList
                  dataKey={bar.dataName}
                  content={renderCustomizedLabel}
                  position="right"
                  fill={_variablesTrafficLight.textBlack}
                />
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default McBarChartTrafficLight;
