import { useState, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import { formatErrorForAlert } from "../../services/UtilsService";
import Alert from "../../components/Alert/Alert";
import DefaultLogin from "./DefaultLogin";
import FirstLogin from "./FirstLogin";
import ForgotPassword from "./ForgotPassword";
import "./Login.css";

const Login = () => {
  const { login } = useContext(AccountContext);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [alert, setAlert] = useState({});
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isReadingTnc, setIsReadingTnc] = useState(false);

  const handleLogin = (email = loginEmail, password = loginPassword) => {
    setIsLoadingButton(true);

    login(email, password)
      .then((res) => {
        if (res.data.first_login) {
          setIsFirstLogin(true);
        }
      })
      .catch((err) => {
        /* setAlert({ message: formatErrorForAlert(err), messageType: "error" }); */
        setAlert({
          message: err.response.data.detail,
          messageType: "error",
        });
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  const handleForgotPassword = () => {
    setIsLoadingButton(true);

    let body = {
      email: loginEmail,
    };
    FetchService.post("/api/auth/forgot-password", body)
      .then(() => {
        setAlert({
          message: "Check your email for a new temporary password",
          messageType: "success",
        });
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  const handleChangePassword = () => {
    setIsLoadingButton(true);

    let body = {
      email: loginEmail,
      old_password: loginPassword,
      new_password: newPassword,
    };
    FetchService.put("/api/users/me/change-password", body)
      .then(() => {
        handleLogin(loginEmail, newPassword);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
        setIsLoadingButton(false);
      });
  };

  return (
    <div className="page-login d-flex flex-column justify-content-center align-items-center">
      <div
        className={
          "text-center " +
          (isReadingTnc ? "container " : "container-login ") +
          (isFirstLogin ? "mt-5" : "")
        }
      >
        <img
          src={process.env.PUBLIC_URL + "/imgs/logo.png"}
          className="logo-login mb-4"
          alt="Mastercard logo"
        />

        {isFirstLogin ? (
          <FirstLogin
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            repeatPassword={repeatPassword}
            setRepeatPassword={setRepeatPassword}
            isLoadingButton={isLoadingButton}
            handleChangePassword={handleChangePassword}
            isReadingTnc={isReadingTnc}
            setIsReadingTnc={setIsReadingTnc}
          />
        ) : isForgotPassword ? (
          <ForgotPassword
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            setIsForgotPassword={setIsForgotPassword}
            isLoadingButton={isLoadingButton}
            handleForgotPassword={handleForgotPassword}
          />
        ) : (
          <DefaultLogin
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            loginPassword={loginPassword}
            setLoginPassword={setLoginPassword}
            isLoadingButton={isLoadingButton}
            handleLogin={handleLogin}
            setIsForgotPassword={setIsForgotPassword}
          />
        )}

        <Alert
          styleClass={""}
          alertText={alert.message}
          styleColor={alert.messageType}
          onClick={setAlert}
          paddingLeft={0}
        />
      </div>
    </div>
  );
};

export default Login;
