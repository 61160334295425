import { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import { SettingsModulesList } from "../../services/ExportService";
import {
  formatErrorForAlert,
  titleCase,
  filterVisibility,
} from "../../services/UtilsService";
import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import Loadable from "../../components/Loader/Loadable";
import { ChevronUp, ChevronDown } from "lucide-react";
import { use } from "react";

const Settings = () => {
  const { sidebarExpanded } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedMerchantId, setSelectedMerchantId] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [charts, setCharts] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [expandedCharts, setExpandedCharts] = useState({});
  const [isLoadingDistinct, setIsLoadingDistinct] = useState(false);
  const [filteredTabs, setFilteredTabs] = useState([]);
  //Distinct
  const [distinctDict, setDistinctDict] = useState([]);

  useEffect(() => {
    getMerchants();
  }, []);

  //dates are passed as a dummy value
  useEffect(() => {
    if (selectedMerchant && selectedModule) {
      getCountries(
        {
          start_date: "200001", //Date dummy perchè il backend le vuole ed è troppa sbatta da fixare
          end_date: "210012",
          merchant_name: selectedMerchant,
        },
        selectedModule
      );
    }
  }, [selectedMerchant, selectedModule]);

  const [renderKeyModule, setRenderKeyModule] = useState(0);
  const [renderKeyTab, setRenderKeyTab] = useState(0);

  const handleSelectMerchant = (e) => {
    if (
      hasUnsavedChanges &&
      !window.confirm("You have unsaved changes. Do you really want to leave?")
    ) {
      return;
    }
    setHasUnsavedChanges(false);
    setSelectedMerchant(e);
    setSelectedMerchantId(
      merchants.find((merchant) => merchant.value === e).id
    );
    setModules(merchants.find((merchant) => merchant.value === e).config);
    setSelectedModule(null);
    setSelectedTab(null);
    setCharts([]);
    setCountries([]);
    setShowSettings(false);
    setRenderKeyModule(renderKeyModule + 1);
  };

  const handleSelectdModule = (e) => {
    if (
      hasUnsavedChanges &&
      !window.confirm("You have unsaved changes. Do you really want to leave?")
    ) {
      return;
    }
    setHasUnsavedChanges(false);
    setSelectedModule(e);
    const selectedModule = modules.find((module) => module.value === e);
    setTabs(selectedModule ? selectedModule.tabs : []);

    setSelectedTab(null);
    setCharts([]);
    setCountries([]);
    setShowSettings(false);
    setRenderKeyTab(renderKeyTab + 1);
  };

  useEffect(() => {
    if (selectedModule) {
      setIsLoadingDistinct(true); // Imposta lo stato di caricamento a true
      const promises = [];
      if (selectedModule === "Authentication") {
        promises.push(
          getDistinctGraphs("authentication-methods", "authe/authe-trends")
        );
        promises.push(getDistinctGraphs("3ds2-versions", "authe/authe-trends"));
        promises.push(
          getDistinctGraphs("decline-reason", "authe/authe-trends")
        );
      } else if (selectedModule === "Authorization") {
        promises.push(
          getDistinctGraphs(
            "high-level-decline-reasons",
            "autho/approval-trends"
          )
        );
        promises.push(
          getDistinctGraphs("decline-reasons", "autho/approval-trends")
        );
        promises.push(
          getDistinctGraphs(
            "transactions-by-subchannel-and-approval-rate",
            "autho/approval-trends"
          )
        );
        promises.push(
          getDistinctGraphs(
            "transactions-by-ticket-range-and-approval-rate",
            "autho/approval-trends"
          )
        );
        promises.push(
          getDistinctGraphs(
            "transactions-by-program-and-approval-rate",
            "autho/approval-trends"
          )
        );
        promises.push(
          getDistinctGraphs("3DS-compliance", "autho/approval-trends")
        );
      } else if (selectedModule === "Fraud") {
        promises.push(
          getDistinctGraphs("fraud-rate-by-ticket-range", "fraud/fraud-trends")
        );
        promises.push(
          getDistinctGraphs(
            "fraud-rate-by-subchannel-and-program",
            "fraud/fraud-trends"
          )
        );
        promises.push(
          getDistinctGraphs("top-5-fraud-reason-code", "fraud/fraud-trends")
        );
      }
      Promise.all(promises).then(() => {
        setIsLoadingDistinct(false);
      });
    }
  }, [selectedModule]);

  useEffect(() => {
    if (!filteredTabs || filteredTabs.length === 0) return;
    const updatedCharts = filteredTabs
      ? filteredTabs.charts.map((chart) => {
          if (chart.isFilterable) {
            distinctDict.forEach((graph) => {
              if (graph.graph_name === chart.graph_name) {
                if (!chart.visibilityRules.globalMetrics) {
                  chart.visibilityRules.globalMetrics = {};
                }
                graph.globalMetrics.forEach((metric) => {
                  if (!chart.visibilityRules.globalMetrics[metric]) {
                    chart.visibilityRules.globalMetrics[metric] = {
                      isVisible: true,
                    };
                  }
                });
              }
            });
          }
          return chart;
        })
      : [];
    setCharts(updatedCharts);
  }, [filteredTabs, distinctDict]);

  const handleSelectTabs = (e) => {
    if (isLoadingDistinct) return;
    if (
      hasUnsavedChanges &&
      !window.confirm("You have unsaved changes. Do you really want to leave?")
    ) {
      return;
    }
    setHasUnsavedChanges(false);
    setSelectedTab(e);
    const filteredTabs = tabs.find((tab) => tab.value === e);
    setFilteredTabs(filteredTabs);
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e;
    setSelectedCountry(selectedCountry);
    if (selectedCountry == 0) return;
    const updatedModules = [...modules];
    const moduleIndex = updatedModules.findIndex(
      (module) => module.value === selectedModule
    );
    const tabIndex = updatedModules[moduleIndex].tabs.findIndex(
      (tab) => tab.value === selectedTab
    );

    if (moduleIndex !== -1 && tabIndex !== -1) {
      updatedModules[moduleIndex].tabs[tabIndex].charts.forEach((chart) => {
        if (!chart.visibilityRules.countries[selectedCountry]) {
          chart.visibilityRules.countries[selectedCountry] = {
            isVisible: true,
            hiddenMetrics: [],
          };
        }
      });
    }

    setModules(updatedModules);
  };

  const handleSettingsLoad = () => {
    setShowSettings(true);
  };

  const getMerchants = () => {
    FetchService.get("/api/admin/merchants")
      .then((res) => {
        const activeMerchants = res.data
          .filter((el) => el.active)
          .map((el) => ({
            id: el.id,
            value: el.name,
            text: el.name,
            config: el.setting_config,
          }))
          .sort((a, b) => a.value.localeCompare(b.value));
        setMerchants(activeMerchants);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const modulesMapping = (selectedModule) => {
    switch (selectedModule) {
      case "Performance":
        return "performance";
      case "Authorization":
        return "autho";
      case "Authentication":
        return "authe";
      case "Fraud":
        return "fraud";
      default:
        throw new Error("Invalid module name");
    }
  };

  const getCountries = (body, selectedModule) => {
    FetchService.post(
      `/api/dashboard/geography/${modulesMapping(selectedModule)}/top`,
      body
    )
      .then((res) => {
        const tempCountries = res.data.countries
          .sort((a, b) => a.localeCompare(b))
          .map((el) => {
            if (selectedMerchant === "GOOGLE" && el !== "UK") return undefined;
            return { value: el, text: titleCase(el) };
          })
          .filter((el) => el !== undefined);
        setCountries([{ value: 0, text: "All countries" }, ...tempCountries]);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getDistinctGraphs = (graphName, module) => {
    FetchService.post(`/api/dashboard/${module}/distinct-${graphName}`, {
      start_date: "201010",
      end_date: "205010",
      merchant_name: selectedMerchant,
    })
      .then((res) => {
        const result = res.data.map((item) => item.value);
        const newGraph = { graph_name: graphName, globalMetrics: result };
        setDistinctDict((prevDistinctDict) => {
          const distinctDictLocal = [...prevDistinctDict, newGraph];
          return distinctDictLocal;
        });
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const handleToggleChange = (type, index, subIndex, chartIndex) => {
    const updatedModules = [...modules];

    if (type === "module") {
      const isVisible = !updatedModules[index].isVisible;
      updatedModules[index].isVisible = isVisible;
      updatedModules[index].tabs.forEach((tab) => {
        tab.isVisible = isVisible;
        tab.charts.forEach((chart) => {
          chart.isVisible = isVisible;
        });
      });
    } else if (type === "tab") {
      const isVisible = !updatedModules[index].tabs[subIndex].isVisible;
      updatedModules[index].tabs[subIndex].isVisible = isVisible;
      updatedModules[index].tabs[subIndex].charts.forEach((chart) => {
        chart.isVisible = isVisible;
      });
    } else if (type === "chart") {
      const chart = updatedModules[index].tabs[subIndex].charts[chartIndex];
      if (!selectedCountry || selectedCountry == 0) {
        chart.isVisible = !chart.isVisible;
      } else {
        if (!chart.visibilityRules.countries[selectedCountry]) {
          chart.visibilityRules.countries[selectedCountry] = {
            isVisible: true,
          };
        } else {
          chart.visibilityRules.countries[selectedCountry].isVisible =
            !chart.visibilityRules.countries[selectedCountry].isVisible;
        }
      }
    }

    setModules(updatedModules);
    setHasUnsavedChanges(true);
  };

  const handleMetricToggle = (chartIndex, metric) => {
    // Create a copy of the modules array to update
    const updatedModules = [...modules];

    // Find the index of the selected module
    const moduleIndex = updatedModules.findIndex(
      (module) => module.value === selectedModule
    );

    // Find the index of the selected tab within the selected module
    const tabIndex = updatedModules[moduleIndex].tabs.findIndex(
      (tab) => tab.value === selectedTab
    );

    // Check if both moduleIndex and tabIndex are valid
    if (moduleIndex !== -1 && tabIndex !== -1) {
      // Get the specific chart within the selected tab
      const chart =
        updatedModules[moduleIndex].tabs[tabIndex].charts[chartIndex];

      // Check if a country is selected and it is not "0"
      if (selectedCountry && selectedCountry !== "0") {
        // Initialize hiddenMetrics array if it doesn't exist
        if (!chart.visibilityRules.countries[selectedCountry].hiddenMetrics) {
          chart.visibilityRules.countries[selectedCountry].hiddenMetrics = [];
        }

        // Check if the metric is already in hiddenMetrics
        if (
          chart.visibilityRules.countries[
            selectedCountry
          ].hiddenMetrics.includes(metric)
        ) {
          // Remove the metric from hiddenMetrics
          chart.visibilityRules.countries[selectedCountry].hiddenMetrics =
            chart.visibilityRules.countries[
              selectedCountry
            ].hiddenMetrics.filter((hiddenMetric) => hiddenMetric !== metric);
        } else {
          // Add the metric to hiddenMetrics
          chart.visibilityRules.countries[selectedCountry].hiddenMetrics.push(
            metric
          );
        }
      } else {
        // If no country is selected, toggle the global metric visibility
        if (!chart.visibilityRules.globalMetrics[metric]) {
          chart.visibilityRules.globalMetrics[metric] = { isVisible: true };
        } else {
          chart.visibilityRules.globalMetrics[metric].isVisible =
            !chart.visibilityRules.globalMetrics[metric].isVisible;
        }
      }
    }

    // Update the state with the modified modules array
    setModules(updatedModules);
    // Set the flag indicating there are unsaved changes
    setHasUnsavedChanges(true);
  };

  const toggleChartExpansion = (index) => {
    setExpandedCharts((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSaveSettings = () => {
    setIsLoadingButton(true);
    FetchService.put(
      `/api/admin/merchants/${selectedMerchantId}/update-config`,
      modules
    )
      .then((res) => {
        setAlert({
          message: `Settings for ${res.data.name} - ${selectedModule}/${selectedTab} saved successfully`,
          messageType: "success",
        });
        setHasUnsavedChanges(false);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  const handleMericCheck = ({ chart, metric }) => {
    if (selectedCountry && selectedCountry !== "0") {
      if (
        chart.visibilityRules.countries[
          selectedCountry
        ]?.hiddenMetrics.includes(metric)
      ) {
        return false;
      }
      return true;
    }
    if (chart.visibilityRules.globalMetrics[metric]?.isVisible) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <Container footer={false}>
      <Title>Settings</Title>

      <Alert
        styleClass={""}
        alertText={alert.message}
        styleColor={alert.messageType}
        onClick={setAlert}
        paddingLeft={sidebarExpanded}
      />
      <div className="row mb-5">
        <div className="col-6 col-lg-3">
          <Select
            options={merchants}
            onChange={handleSelectMerchant}
            placeholder="Select Merchant"
            styleClass="w-100"
          />
        </div>
        <div className="col-6 col-lg-3">
          <Select
            key={renderKeyModule}
            options={modules}
            onChange={handleSelectdModule}
            placeholder="Select Module"
            styleClass="w-100"
            disabled={!selectedMerchant}
          />
        </div>
        <div className="col-6 col-lg-3">
          <Select
            key={renderKeyTab}
            options={tabs}
            onChange={handleSelectTabs}
            placeholder="Select Tab"
            styleClass="w-100"
            disabled={!selectedModule}
          />
        </div>
        <div className="col-lg-2 mt-2 mt-lg-0 text-end align-self-center">
          <Loadable isLoading={isLoadingButton}>
            <Button
              text="Select"
              onClick={handleSettingsLoad}
              disabled={!selectedTab}
            />
          </Loadable>
        </div>
      </div>
      {showSettings && (
        <div className="row">
          <div className="col-5">
            <Title>{selectedMerchant}</Title>

            {selectedModule && (
              <div className="">
                <div className="toggle-box-module">
                  <div
                    className={`menu-item large-font ${
                      !modules.find((module) => module.value === selectedModule)
                        .isVisible
                        ? "disabled-text"
                        : ""
                    }`}
                  >
                    <strong>{selectedModule}</strong>
                  </div>
                  <Form.Check
                    type="switch"
                    aria-label={selectedModule}
                    checked={
                      modules.find((module) => module.value === selectedModule)
                        .isVisible
                    }
                    className="toggle-switch"
                    onChange={() =>
                      handleToggleChange(
                        "module",
                        modules.findIndex(
                          (module) => module.value === selectedModule
                        )
                      )
                    }
                  />
                </div>
              </div>
            )}

            {selectedTab && (
              <div className="ms-2">
                <div className="toggle-box-tab">
                  <div
                    className={`menu-item large-font ${
                      !tabs.find((tab) => tab.value === selectedTab).isVisible
                        ? "disabled-text"
                        : ""
                    }`}
                  >
                    <strong>{selectedTab}</strong>
                  </div>
                  <Form.Check
                    type="switch"
                    aria-label={selectedTab}
                    checked={
                      tabs.find((tab) => tab.value === selectedTab).isVisible
                    }
                    className="toggle-switch"
                    onChange={() =>
                      handleToggleChange(
                        "tab",
                        modules.findIndex(
                          (module) => module.value === selectedModule
                        ),
                        tabs.findIndex((tab) => tab.value === selectedTab)
                      )
                    }
                    disabled={
                      !modules.find((module) => module.value === selectedModule)
                        .isVisible
                    }
                  />
                </div>

                <div className="toggle-group">
                  {tabs.find((tab) => tab.value === selectedTab)
                    .hasCountryFilter && (
                    <Select
                      options={countries}
                      onChange={handleCountryChange}
                      placeholder="Select Country"
                      styleClass="w-100"
                      disabled={
                        !tabs.find((tab) => tab.value === selectedTab).isVisible
                      }
                    />
                  )}
                  {charts.map((chart, index) => {
                    const isVisible = selectedCountry
                      ? chart.visibilityRules.countries[selectedCountry]
                          ?.isVisible ?? chart.isVisible
                      : chart.isVisible;

                    return (
                      <div key={index}>
                        <div
                          className={`toggle-box-chart ${
                            !isVisible ? "disabled-text" : ""
                          }`}
                        >
                          <div className="menu-item">
                            {chart.isFilterable && (
                              <span onClick={() => toggleChartExpansion(index)}>
                                {expandedCharts[index] ? (
                                  <ChevronUp />
                                ) : (
                                  <ChevronDown />
                                )}
                              </span>
                            )}
                            <b>{chart.text}</b>
                          </div>
                          <Form.Check
                            type="switch"
                            aria-label={chart.text}
                            checked={isVisible}
                            className="toggle-switch"
                            onChange={() =>
                              handleToggleChange(
                                "chart",
                                modules.findIndex(
                                  (module) => module.value === selectedModule
                                ),
                                tabs.findIndex(
                                  (tab) => tab.value === selectedTab
                                ),
                                index
                              )
                            }
                            disabled={
                              !tabs.find((tab) => tab.value === selectedTab)
                                .isVisible
                            }
                          />
                        </div>
                        {chart.isFilterable &&
                          expandedCharts[index] &&
                          !isLoadingDistinct && (
                            <div className="metrics-container">
                              {Object.keys(
                                chart.visibilityRules.globalMetrics
                              ).map((metric) => (
                                <div
                                  key={`${selectedTab}-${metric}`}
                                  className={`toggle-box-metric ${
                                    !handleMericCheck({ chart, metric }) ||
                                    !isVisible
                                      ? "disabled-text"
                                      : ""
                                  }`}
                                >
                                  <div className="menu-item">{metric}</div>
                                  <Form.Check
                                    type="switch"
                                    aria-label={metric}
                                    checked={handleMericCheck({
                                      chart,
                                      metric,
                                    })}
                                    className="toggle-switch"
                                    onChange={() =>
                                      handleMetricToggle(index, metric)
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="mt-3">
              <Loadable isLoading={isLoadingButton}>
                <Button
                  text="Confirm"
                  onClick={handleSaveSettings}
                  disabled={!hasUnsavedChanges}
                />
              </Loadable>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Settings;
