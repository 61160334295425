const SidebarService = [
  {
    title: "Add User",
    shortName: "Add",
    path: "/add-user",
    category: "add-user",
    auth: ["admin"],
    icon: process.env.PUBLIC_URL + "/imgs/adduser.png",
  },
  {
    title: "Analytics",
    shortName: "Analytics",
    path: "/analytics",
    category: "analytics",
    auth: ["admin"],
    icon: process.env.PUBLIC_URL + "/imgs/analytics.png",
  },
  {
    title: "Settings",
    shortName: "Settings",
    path: "/settings",
    category: "settings",
    auth: ["admin"],
    icon: process.env.PUBLIC_URL + "/imgs/settings.png",
  },
  {
    title: "Performance",
    shortName: "Perf",
    path: "/performance/home",
    category: "performance",
    auth: ["user"],
    icon: process.env.PUBLIC_URL + "/imgs/performance.png",
  },
  {
    title: "Authentication",
    shortName: "AuthE",
    path: "/authentication/home",
    category: "authentication",
    auth: ["user", "acquirer"],
    icon: process.env.PUBLIC_URL + "/imgs/authentication.png",
  },
  {
    title: "Authorization",
    shortName: "AuthO",
    path: "/authorization/home",
    category: "authorization",
    auth: ["user", "acquirer"],
    icon: process.env.PUBLIC_URL + "/imgs/authorization.png",
  },
  {
    title: "Fraud",
    shortName: "Fraud",
    path: "/fraud/home",
    category: "fraud",
    auth: ["user", "acquirer"],
    icon: process.env.PUBLIC_URL + "/imgs/fraud.png",
  },
];

export default SidebarService;
