import Kpi from "./Kpi";
import { _variablesTwoOranges } from "../../styles/_variables";

const KpiRow = ({ kpis, offset = false }) => {
  return (
    <div className="row">
      {offset && (
        <div
          className={
            "col-12 " +
            (kpis.length == 2 ? "col-xl-1" : "col-xl-2") +
            " mt-2 mt-xl-0"
          }
        ></div>
      )}
      {kpis ? (
        kpis.map((kpi, index) => {
          if (Object.keys(kpi).length === 0) return <></>;
          return (
            <div
              className={
                "col-12 col-lg-6 " +
                (kpis.length == 2 ? "col-xl-5" : "col-xl-4") +
                " mt-2 mt-xl-0"
              }
              key={index + "kpi" + Math.random()}
            >
              <Kpi
                leftContent={kpi.leftContent}
                rightContent={kpi.righContent}
                color={kpi.color}
                autofit={kpi.autofit}
                styleClass={kpi.styleClass}
              />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default KpiRow;
