import { useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import SmallTitle from "../../components/Title/SmallTitle";
import { _variablesTwoOranges } from "../../styles/_variables";
import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";

const EmptyPage = () => {
  const { merchantName } = useContext(AccountContext);
  return (
    <Container footer="">
      <Title>No Modules Active for the current Merchant</Title>
      <SmallTitle>
        All the modules for {merchantName} have been disabled. Please contact
        the administrator.
      </SmallTitle>
    </Container>
  );
};

export default EmptyPage;
