import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  generateLastMonths,
  formatNumberFull,
  formatErrorForAlert,
  titleCase,
  sortTicketRanges,
  assembleBody,
  assembleBodyWithProductType,
  assembleBodyFinal,
  findMax,
  fill3DSMissingData,
  filterVisibility,
  filterMetrics,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import Kpi from "../../components/Kpi/Kpi";
import KpiBig from "../../components/Kpi/KpiBig";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import McBarChartVertical from "../../components/McCharts/McBarChartVertical";
import McBarChartTrafficLight from "../../components/McCharts/McBarChartTrafficLight";
import { _variablesTwoOranges } from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import Switcher from "../../components/Switcher/Switcher";
import LayoutFilter from "../../components/Layout/LayoutFilter";
import McBarChart from "../../components/McCharts/McBarChart";
import McBarChartStacked from "../../components/McCharts/McBarChartStacked";
import Download from "../../components/Button/Download";

const FraudHome = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    userEmail,
    fraudDates,
    settingsConfig,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});

  const [dateLabels, setDateLabels] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const settings = settingsConfig ? settingsConfig : [];

  // charts
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState({});
  const [dataKpisAvg, setDataKpisAvg] = useState({});
  const [isLoadingByTicket, setIsLoadingByTicket] = useState(true);
  const [dataByTicket, setDataByTicket] = useState([]);
  const [isLoadingByProgram, setIsLoadingByProgram] = useState(true);
  const [dataByProgram, setDataByProgram] = useState([]);
  const [isLoadingTopFifteen, setIsLoadingTopFifteen] = useState(true);
  const [dataTopFifteenTransactions, setDataTopFifteenTransactions] = useState(
    []
  );
  const [dataTopFifteenRate, setDataTopFifteenRate] = useState([]);
  // product type
  const [productType, setProductType] = useState("");
  const [transactionType, setTransactionType] = useState("count");
  // top 5 fraud reason code split
  const [dataTopFiveReasonCodeSplit, setDataTopFiveReasonCodeSplit] =
    useState();
  const [maxReasonCodeSplit, setMaxReasonCodeSplit] = useState();
  const [isLoadingTopFiveReasonCodeSplit, setIsLoadingTopFiveReasonCodeSplit] =
    useState(true);
  // fraud rate by subchannel
  const [dataBySubchannel, setDataBySubchannel] = useState([]);
  const [isLoadingBySubchannel, setIsLoadingBySubchannel] = useState(true);
  const [dataBySubchannelAndProgram, setDataBySubchannelAndProgram] = useState(
    []
  );
  const [isLoadingBySubchannelAndProgram, setIsLoadingBySubchannelAndProgram] =
    useState(true);

  // get dates for timeperiod
  useEffect(() => {
    if (fraudDates) {
      setDateLabels(fraudDates);
    } else {
      setAlert({
        message: "Fraud dates not available",
        messageType: "error",
      });
    }
  }, [fraudDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType,
          transactionType === "count" ? false : true
        );

        getKpis(body);

        // special body for the filter
        let bodyFilter = assembleBodyFinal(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType,
          transactionType === "count" ? false : true
        );
        getByTicket(bodyFilter);
        getByProgram(bodyFilter);
        getKpisAvg(bodyFilter);
        getTopFifteen(body);
        getTopFiveReasonCodeSplit(body);
        getBySubchannel(body);
        getBySubchannelAndProgram(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  // get charts data based on transaction type filter
  useEffect(() => {
    setIsLoadingByProgram(true);
    setIsLoadingByTicket(true);
    setIsLoadingTopFifteen(true);
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      // special body for the filter
      let bodyFilter = assembleBodyFinal(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        acquirerName,
        productType,
        transactionType === "count" ? false : true
      );
      getByTicket(bodyFilter);
      getByProgram(bodyFilter);
      getTopFifteen(bodyFilter);
      getKpisAvg(bodyFilter);
      getBySubchannelAndProgram(bodyFilter);
    }
  }, [transactionType]);

  const isLoadingAll = () => {
    setIsLoadingKpis(true);
    setIsLoadingByTicket(true);
    setIsLoadingByProgram(true);
    setIsLoadingTopFifteen(true);
    setIsLoadingTopFiveReasonCodeSplit(true);
    setIsLoadingBySubchannel(true);
    setIsLoadingBySubchannelAndProgram(true);
  };

  const getKpis = (body) => {
    setIsLoadingKpis(true);

    FetchService.post("/api/dashboard/fraud/fraud-trends/performance", body)
      .then((res) => {
        setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getKpisAvg = (body) => {
    setIsLoadingKpis(true);

    FetchService.post("/api/dashboard/fraud/fraud-trends/performance", body)
      .then((res) => {
        setDataKpisAvg(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getByTicket = (body) => {
    setIsLoadingByTicket(true);

    FetchService.post(
      "/api/dashboard/fraud/fraud-trends/fraud-rate-by-ticket-range",
      body
    )
      .then((res) => {
        res.data = filterMetrics(
          res.data,
          settings,
          "Fraud",
          "Overview",
          "Fraud rate by ticket range",
          "label"
        );
        res.data.forEach((el) => (el.label = el.label + " €"));
        setDataByTicket(sortTicketRanges(res.data, "€"));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTicket(false));
  };

  const getByProgram = (body) => {
    setIsLoadingByProgram(true);

    FetchService.post(
      "/api/dashboard/fraud/fraud-trends/fraud-rate-by-program",
      body
    )
      .then((res) => {
        setDataByProgram(fill3DSMissingData(res.data));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByProgram(false));
  };

  const getTopFifteen = (body) => {
    setIsLoadingTopFifteen(true);

    FetchService.post(
      "/api/dashboard/fraud/fraud-trends/top-15-issuer-by-transactions",
      body
    )
      .then((res) => {
        let tempTransactions = [];
        let tempRate = [];

        res.data.forEach((el) => {
          tempTransactions.push({
            country: el.issuer ? titleCase(el.issuer) : "",
            value: el.sum_trx ? el.sum_trx / 1000 : 0,
          });
          tempRate.push({
            country: el.issuer ? titleCase(el.issuer) : "",
            value: el.fraud_rate ? el.fraud_rate : 0,
            avg: el.above_average ? el.above_average : false,
          });
        });

        setDataTopFifteenTransactions(tempTransactions);
        setDataTopFifteenRate(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFifteen(false));
  };

  const getTopFiveReasonCodeSplit = (body) => {
    setIsLoadingTopFiveReasonCodeSplit(true);

    FetchService.post(
      "/api/dashboard/fraud/fraud-trends/top-5-fraud-reason-code",
      body
    )
      .then((res) => {
        res.data = filterMetrics(
          res.data,
          settings,
          "Fraud",
          "Overview",
          "Fraud transaction share by reason code",
          "label"
        );
        let sortData = res.data.sort((a, b) => b.percent - a.percent);
        setIsLoadingTopFiveReasonCodeSplit(sortData);
        setMaxReasonCodeSplit(findMax(sortData));
        setDataTopFiveReasonCodeSplit(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFiveReasonCodeSplit(false));
  };

  const getBySubchannel = (body) => {
    setIsLoadingBySubchannel(true);

    FetchService.post(
      "/api/dashboard/fraud/fraud-trends/fraud-rate-by-subchannel",
      body
    )
      .then((res) => {
        setDataBySubchannel(res.data.sort((a, b) => b.value - a.value));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannel(false));
  };

  const getBySubchannelAndProgram = (body) => {
    setIsLoadingBySubchannelAndProgram(true);

    FetchService.post(
      "/api/dashboard/fraud/fraud-trends/fraud-rate-by-subchannel-and-program",
      body
    )
      .then((res) => {
        res.data = filterMetrics(
          res.data,
          settings,
          "Fraud",
          "Overview",
          "Fraud rate by subchannel & program",
          "subchannel"
        );
        setDataBySubchannelAndProgram(
          res.data.sort(
            (a, b) =>
              b.rate_3ds + b.rate_non_3ds - (a.rate_3ds + a.rate_non_3ds)
          )
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannelAndProgram(false));
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  function mergeTopFifteen(array1, array2) {
    return array1.map((item1) => {
      const item2 = array2.find((item2) => item2.country === item1.country);
      return item2
        ? {
            ...item1,
            authRate: item2.value,
            issuer: item1.country,
          }
        : item1;
    });
  }

  // Visibility settings
  const getVisibility = (graphName) => {
    return filterVisibility("chart", settings, "Fraud", "Overview", graphName);
  };

  const top15Vix = getVisibility("Top 15 issuers by transactions");
  const performanceVix = getVisibility("Fraud performance");
  const ticketRangeVix = getVisibility("Fraud rate by ticket range");
  const subChannelProgramVix = getVisibility(
    "Fraud rate by subchannel & program"
  );
  const programVix = getVisibility("Fraud rate by program");
  const trxShareReasonCodeVix = getVisibility(
    "Fraud transaction share by reason code"
  );

  const tickerRangeContent = ticketRangeVix ? (
    <>
      <div className="d-flex justify-content-between">
        <SmallTitleFlex
          title={"Fraud rate by ticket range"}
          tooltip={
            "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each ticket range.<br /><br />" +
            "100 bps = 1%"
          }
        />
        <div style={{ marginRight: "2rem" }}>
          {dashboardVersion2 && (
            <Download
              section="FraudOverview"
              chartName="Fraud rate by ticket range"
              data={dataByTicket}
              graphType="pie"
              label={"Ticket Range"}
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              measure={
                transactionType === "count" ? "Transaction Count" : "Volumes"
              }
              fields={[{ field: "Fraud Rate", value: "value" }]}
              iconSize="sm"
              className="h-8 w-8"
            />
          )}
        </div>
      </div>
      <McBarChartTrafficLight
        height={60 + (dataByTicket.length > 0 ? dataByTicket.length * 50 : 300)}
        data={dataByTicket}
        dataGuide={{
          y: "label",
          barsData: [{ dataName: "value", friendlyName: "Fraud rate" }],
        }}
        tooltipMeasureUnit=" bps"
        marginLeft
      />
    </>
  ) : null;

  const subChannelProgramContent =
    subChannelProgramVix || programVix ? (
      <>
        {dashboardVersion2 ? (
          <>
            <div className="d-flex justify-content-between">
              <SmallTitleFlex
                title={"Fraud rate by subchannel & program"}
                tooltip={
                  "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program and subchannel.<br /><br />" +
                  "100 bps = 1%"
                }
              />
              <div style={{ marginRight: "2rem" }}>
                <Download
                  section="FraudOverview"
                  chartName="Fraud rate by subchannel & program"
                  data={dataBySubchannelAndProgram}
                  graphType="pie"
                  label={"Subchannel "}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[
                    { field: "3DS", value: "rate_3ds" },
                    { field: "Non 3DS", value: "rate_non_3ds" },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </div>
            </div>
            <McBarChartStacked
              height={
                120 +
                (dataBySubchannelAndProgram.length > 0
                  ? dataBySubchannelAndProgram.length * 60
                  : 120)
              }
              data={dataBySubchannelAndProgram}
              dataGuide={{
                y: "subchannel",
                barsData: [
                  {
                    firstBar: "rate_3ds",
                    secondBar: "rate_non_3ds",
                    friendlyName: "Fraud rate by subchannel and program",
                  },
                ],
              }}
              tooltipMeasureUnit=" bps"
              marginLeft
              background={false}
            />
          </>
        ) : (
          <>
            <SmallTitleFlex
              title={"Fraud rate by program"}
              tooltip={
                "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program.<br /><br />" +
                "100 bps = 1%"
              }
            />
            <McBarChartTrafficLight
              height={
                60 +
                (dataByProgram.length > 0 ? dataByProgram.length * 60 : 120)
              }
              data={dataByProgram}
              dataGuide={{
                y: "label",
                barsData: [{ dataName: "value", friendlyName: "Fraud rate" }],
              }}
              tooltipMeasureUnit=" bps"
              marginRight
            />
          </>
        )}
      </>
    ) : null;

  return (
    <LayoutPageContainer
      title={"Fraud"}
      pages={[
        {
          title: "Overview",
          link: "/fraud/home",
          active: true,
        },
        { title: "Geography", link: "/fraud/geography" },
        { title: "Benchmark", link: "/fraud/benchmark" },
        isMultiAcquirer && !acquirerName
          ? { title: "Acquirers", link: "/fraud/acquirers" }
          : {},
      ]}
      alert={alert}
      setAlert={setAlert}
      settings={settings}
    >
      <Refreshing
        conditions={[
          isLoadingKpis,
          isLoadingByTicket,
          isLoadingByProgram,
          isLoadingTopFifteen,
          isLoadingTopFiveReasonCodeSplit,
          isLoadingBySubchannel,
          isLoadingBySubchannelAndProgram,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}
      <hr />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          isShared={true}
          sharedState={transactionType}
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
              value: "count",
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
              value: "volume",
            },
          ]}
        />
      </div> */}
      {top15Vix && (
        <>
          <div className="d-flex justify-content-between">
            <SmallTitleFlex
              title={"Top 15 issuers by transactions"}
              tooltip={
                "Top 15 Issuers listed in descending order by number of transactions."
              }
            />
            <Download
              section="FraudOverview"
              chartName="Top 15 issuers by transactions"
              data={mergeTopFifteen(
                dataTopFifteenTransactions,
                dataTopFifteenRate
              )}
              graphType="barChart"
              productType={productType}
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              label={"Issuer"}
              field1={transactionType === "count" ? "Transactions" : "Volumes"}
              value1={"value"}
              field2={"Approval Rate (%)"}
              value2={"authRate"}
              iconSize="sm"
              className="h-8 w-8"
            />
          </div>
          <Switcher
            isShared={true}
            sharedState={transactionType}
            pages={[
              {
                title: "Transactions",
                onClick: () => {
                  setTransactionType("count");
                },
                value: "count",
              },
              {
                title: "Volumes",
                onClick: () => {
                  setTransactionType("volume");
                },
                value: "volume",
              },
            ]}
          />
          <LayoutTwoColumns
            leftContent={
              <>
                <div className="text-center mb-3">
                  {transactionType === "count"
                    ? "Transactions (K)"
                    : "Volumes (K€)"}
                </div>
                <McBarChartVertical
                  background={false}
                  data={dataTopFifteenTransactions}
                  dataGuide={{
                    y: "country",
                    barsData:
                      transactionType === "count"
                        ? [{ dataName: "value", friendlyName: "Transactions" }]
                        : [{ dataName: "value", friendlyName: "Volumes" }],
                  }}
                  tooltipMeasureUnit={
                    transactionType === "count" ? " K" : " K€"
                  }
                  hideNamesIssuers={userEmail === "demo@mastercard.it"}
                />
              </>
            }
            rightContent={
              <>
                <div className="text-center mb-3">Fraud rate (bps)</div>
                <McBarChartVertical
                  data={dataTopFifteenRate}
                  dataGuide={{
                    y: "country",
                    barsData: [{ dataName: "value", friendlyName: "Rate" }],
                  }}
                  labels={["Rate (bps)"]}
                  legend
                  tooltipMeasureUnit=" bps"
                  avg={dataKpisAvg.fraud_rate}
                  hideNamesIssuers={userEmail === "demo@mastercard.it"}
                />
              </>
            }
          />
        </>
      )}

      <hr />

      {performanceVix && (
        <>
          <SmallTitleFlex
            title={"Fraud performance"}
            tooltip={
              "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions.<br /><br />" +
              "Average genuine/fraudulent tickets are calculated as: volumes (spend) divided by transactions.<br /><br />" +
              "100 bps = 1%"
            }
          />

          <div className="row">
            <div className="col-12 col-lg-6">
              <KpiBig
                bottomContent={
                  (dataKpis.fraud_rate
                    ? formatNumberFull(dataKpis.fraud_rate)
                    : 0) + " bps"
                }
                topContent={"Fraud rate"}
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="row mb-2">
                <div className="col-12 col-lg-6">
                  <Kpi
                    leftContent={
                      (dataKpis.fraud_trx
                        ? formatNumberFull(dataKpis.fraud_trx / 1000)
                        : 0) + " K"
                    }
                    rightContent={"Fraudulent transactions"}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <Kpi
                    leftContent={
                      (dataKpis.fraud_amount
                        ? formatNumberFull(dataKpis.fraud_amount)
                        : 0) + " €"
                    }
                    rightContent={"Fraudulent amount"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Kpi
                    leftContent={
                      (dataKpis.avg_genuine_ticket
                        ? formatNumberFull(dataKpis.avg_genuine_ticket)
                        : 0) + " €"
                    }
                    rightContent={"Average genuine ticket"}
                    color={_variablesTwoOranges.primaryOrangeLight}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <Kpi
                    leftContent={
                      (dataKpis.avg_fraud_ticket
                        ? formatNumberFull(dataKpis.avg_fraud_ticket)
                        : 0) + " €"
                    }
                    rightContent={"Average fraudulent ticket"}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          isShared={true}
          sharedState={transactionType}
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
              value: "count",
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
              value: "volume",
            },
          ]}
        />
      </div>
      <LayoutTwoColumns
        leftContent={tickerRangeContent}
        rightContent={subChannelProgramContent}
      />

      {dashboardVersion2 && (
        <>
          <hr />
          {trxShareReasonCodeVix && (
            <>
              <div className="d-flex justify-content-between">
                <SmallTitleFlex
                  title={"Fraud transaction share by reason code"}
                  tooltip={
                    "Fraud transactions share split over the different fraud reason codes"
                  }
                />
                <Download
                  section="FraudOverview"
                  chartName="Transaction share by reason code"
                  data={dataTopFiveReasonCodeSplit}
                  graphType="pie"
                  label={"Reason Code"}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[{ field: "Percent (%)", value: "percent" }]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </div>
              <McBarChart
                data={dataTopFiveReasonCodeSplit}
                domain={[0, 100]}
                dataGuide={{
                  x: "label",
                  barsData: [
                    { dataName: "percent", friendlyName: "Percentage" },
                  ],
                }}
                labels={["Fraud Categories"]}
                twoYs={false}
                maxData={maxReasonCodeSplit}
                isPercentage={true}
              />
            </>
          )}
        </>
      )}
      <hr />
    </LayoutPageContainer>
  );
};

export default FraudHome;
