import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatNumberRound,
  formatErrorForAlert,
  titleCase,
  sortTicketRanges,
  assembleBody,
  mergeData,
  padArray,
  ukMerchants,
  filterVisibility,
  filterMetrics,
} from "../../services/UtilsService";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import LayoutBenchmarkFilter from "../../components/Layout/LayoutBenchmarkFilter";
import KpiColumn from "../../components/Kpi/KpiColumn";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import KpiProgressChart from "../../components/Kpi/KpiProgressChart";
import McDonutChartDouble from "../../components/McCharts/McDonutChartDouble";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McBarChart from "../../components/McCharts/McBarChart";
import McTreemapChart from "../../components/McCharts/McTreemapChart";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import Title from "../../components/Title/Title";
import {
  _variablesTwoOranges,
  _variablesThreeOrangesRate,
  _variablesSix,
} from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import Modal from "../../components/Modal/Modal";
import TableAG from "../../components/Table/TableAG";
import Button from "../../components/Button/Button";
import SmallTitle from "../../components/Title/SmallTitle";
import Download from "../../components/Button/Download";
import { UndoIcon } from "lucide-react";

const colsDef = [
  { field: "auth_method", headerName: "Authentication Method", flex: 1 },
  {
    headerName: "Challenge Code",
    marryChildren: true,
    children: [
      {
        field: "percent_zerofour",
        headerName: "04",
        flex: 1,
      },
      { field: "percent_zerofive", headerName: "05", flex: 1 },
    ],
  },
  {
    field: "total_trx",
    headerName: "Total Transaction Count",
    flex: 1,
    valueFormatter: (params) => Number(params.value).toLocaleString("en-US"),
  },
];

const AuthenticationBenchmark = () => {
  // general
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    currency,
    autheDates,
    settingsConfig,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);

  // geography & benchmark
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState("0");

  // charts
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState([]);
  const [isLoadingFrictionless, setIsLoadingFrictionless] = useState(true);
  const [dataFrictionless, setDataFrictionless] = useState([]);
  const [sideTextRowsFrictionless, setSideTextRowsFrictionless] = useState([]);
  const [isLoadingChallengeSuccess, setIsLoadingChallengeSuccess] =
    useState(true);
  const [dataChallengeSuccess, setDataChallengeSuccess] = useState([]);
  const [sideTextRowsChallengeSuccess, setSideTextRowsChallengeSuccess] =
    useState([]);
  const [isLoadingTransactionsDevice, setIsLoadingTransactionsDevice] =
    useState(true);
  const [dataTransactionsDevice, setDataTransactionsDevice] = useState([]);
  const [sideBarsTransactionsDevice, setSideBarsTransactionsDevice] = useState(
    []
  );
  const [
    isLoadingTransactionsTicketRange,
    setIsLoadingTransactionsTicketRange,
  ] = useState(true);
  const [dataTransactionsTicketRange, setDataTransactionsTicketRange] =
    useState([]);
  const [isLoadingDecline, setIsLoadingDecline] = useState(true);
  const [dataDecline, setDataDecline] = useState([]);
  const [listDeclineReasons, setListDeclineReasons] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const currencySymbol = currency === 826 ? "£" : "€";
  const settings = settingsConfig ? settingsConfig : [];

  // charts countries
  const [dataKpisCountries, setDataKpisCountries] = useState([]);
  const [dataFrictionlessCountries, setDataFrictionlessCountries] = useState(
    []
  );
  const [
    sideTextRowsFrictionlessCountries,
    setSideTextRowsFrictionlessCountries,
  ] = useState([]);
  const [dataChallengeSuccessCountries, setDataChallengeSuccessCountries] =
    useState([]);
  const [
    sideTextRowsChallengeSuccessCountries,
    setSideTextRowsChallengeSuccessCountries,
  ] = useState([]);
  const [dataTransactionsDeviceCountries, setDataTransactionsDeviceCountries] =
    useState([]);
  const [
    sideBarsTransactionsDeviceCountries,
    setSideBarsTransactionsDeviceCountries,
  ] = useState([]);
  const [
    dataTransactionsTicketRangeCountries,
    setDataTransactionsTicketRangeCountries,
  ] = useState([]);
  const [dataDeclineCountries, setDataDeclineCountries] = useState([]);

  // 3ds2 versions
  const [isLoading3ds2Versions, setIsLoading3ds2Versions] = useState(true);
  const [data3ds2Versions, setData3ds2Versions] = useState([]);
  const [data3ds2VersionsBarChart, setData3ds2VersionsBarChart] = useState([]);
  const [data3ds2VersionsCountries, setData3ds2VersionsCountries] = useState(
    []
  );
  const [
    data3ds2VersionsBarChartCountries,
    setData3ds2VersionsBarChartCountries,
  ] = useState([]);

  // auth methods
  const [isLoadingAuthMethods, setIsLoadingAuthMethods] = useState(true);
  const [dataAuthMethods, setDataAuthMethods] = useState([]);
  const [dataTopFiveAuthMethods, setDataTopFiveAuthMethods] = useState([]);
  const [dataAuthMethodsCountries, setDataAuthMethodsCountries] = useState([]);
  const [dataTopFiveAuthMethodsCountries, setDataTopFiveAuthMethodsCountries] =
    useState([]);
  // challenge code
  const [dataChallengeCode, setDataChallengeCode] = useState([]);
  const [dataChallengeCodeCountries, setDataChallengeCodeCountries] = useState(
    []
  );
  const [isLoadingChallengeCode, setIsLoadingChallengeCode] = useState();
  const [dataChallengeCodeForAuthMethod, setDataChallengeCodeForAuthMethod] =
    useState([]);
  const [
    dataChallengeCodeForAuthMethodCountries,
    setDataChallengeCodeForAuthMethodCountries,
  ] = useState([]);
  const [
    isLoadingChallengeCodeForAuthMethod,
    setIsLoadingChallengeCodeForAuthMethod,
  ] = useState();
  //api response
  const [apiDataAuthMethods, setApiDataAuthMethods] = useState([]);
  const [apiDataAuthMethodsCountries, setApiDataAuthMethodsCountries] =
    useState([]);
  //merged data
  const [mergedDataTicketRange, setMergedDataTicketRange] = useState([]);
  const [mergedDataDeclineReasons, setMergedDataDeclineReasons] = useState([]);
  const [mergedDataAuthMethods, setMergedDataAuthMethods] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    if (autheDates) {
      setDateLabels(autheDates);
    } else {
      setAlert({
        message: "Authentication dates not available",
        messageType: "error",
      });
    }
  }, [autheDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBody(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          false,
          undefined,
          currency
        );

        // get countries for select
        getCountries(body);

        getKpis(body, "geography");
        getFrictionless(body, "geography");
        getChallengeSuccess(body, "geography");
        getTransactionsDevice(body, "geography");
        getTransactionsTicketRange(body, "geography");
        getDecline(body, "geography");
        get3ds2Versions(body, "geography");
        getAuthMethods(body, "geography");
        getChallengeCode(body, "geography");
        getChallengeCodeForAuthMethod(body, "geography");
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels]);

  // get charts data based on country
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (countries.length > 0) {
        let body = assembleBodyCountry();

        getKpis(body, "benchmark");
        getKpis(body, "geography");

        getFrictionless(body, "benchmark");
        getFrictionless(body, "geography");

        getChallengeSuccess(body, "benchmark");
        getChallengeSuccess(body, "geography");

        getTransactionsDevice(body, "benchmark");
        getTransactionsDevice(body, "geography");

        getTransactionsTicketRange(body, "benchmark");
        getTransactionsTicketRange(body, "geography");

        getDecline(body, "benchmark");
        getDecline(body, "geography");

        get3ds2Versions(body, "benchmark");
        get3ds2Versions(body, "geography");

        getAuthMethods(body, "benchmark");
        getAuthMethods(body, "geography");

        getChallengeCode(body, "benchmark");
        getChallengeCode(body, "geography");

        getChallengeCodeForAuthMethod(body, "benchmark");
        getChallengeCodeForAuthMethod(body, "geography");
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, selectedCountries, countries]);

  const isLoadingAll = () => {
    setIsLoadingKpis(true);
    setIsLoadingFrictionless(true);
    setIsLoadingChallengeSuccess(true);
    setIsLoadingTransactionsDevice(true);
    setIsLoadingTransactionsTicketRange(true);
    setIsLoadingDecline(true);
    setIsLoading3ds2Versions(true);
    setIsLoadingAuthMethods(true);
    setIsLoadingChallengeCode(true);
    setIsLoadingChallengeCodeForAuthMethod(true);
  };

  const assembleBodyCountry = () => {
    // add all countries in case of 0
    /* let country = [];
    if (selectedCountries === 0) {
      country = countries.map((c) => c.value);
      country = country.filter(
        (c) => c !== 0 && c !== "" && c !== undefined && c !== null
      );
    } else {
      country = [selectedCountries];
    } */

    let body = assembleBody(
      merchantName,
      dateLabels[timePeriod[0]],
      dateLabels[timePeriod[1]],
      acquirerName,
      false,
      undefined,
      currency
    );

    if (selectedCountries !== 0 && selectedCountries !== "0") {
      body.country = [selectedCountries];
    }

    return body;
  };

  const handleSelectCountry = (e) => {
    setSelectedCountries(e);
  };

  const getCountries = (body) => {
    body.only_euro = true;

    FetchService.post("/api/dashboard/geography/authe/top", body)
      .then((res) => {
        let tempCountries = res.data.countries.sort((a, b) =>
          a.localeCompare(b)
        );
        tempCountries = tempCountries
          .map((el) => {
            // For Google hide Other value
            if (merchantName === "GOOGLE" && el !== "UK") return undefined;
            return { value: el, text: titleCase(el) };
          })
          .filter((el) => el !== undefined);
        tempCountries = [{ value: 0, text: "All countries" }, ...tempCountries];

        setCountries(tempCountries);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getKpis = (body, domain) => {
    setIsLoadingKpis(true);

    FetchService.post(`/api/dashboard/authe/${domain}/performance`, body)
      .then((res) => {
        if (domain === "benchmark") {
          setDataKpisCountries(res.data);
        } else {
          setDataKpis(res.data);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getFrictionless = (body, domain) => {
    setIsLoadingFrictionless(true);

    FetchService.post(
      `/api/dashboard/authe/${domain}/percentage-frictionless-penetration`,
      body
    )
      .then((res) => {
        let frictionless = res.data.frictionless ? res.data.frictionless : 0;
        let acquirer = res.data.acquirer_exemption
          ? res.data.acquirer_exemption
          : 0;
        let tempData = [
          [
            { name: "Frictionless transactions", value: frictionless },
            { name: "Challenge transactions", value: 100 - frictionless },
          ],
          [
            { name: "With Acquirer exemptions", value: acquirer },
            { name: "Without Acquirer exemptions", value: 100 - acquirer },
          ],
        ];
        let tempRows = [
          {
            leftContent: formatNumberFull(frictionless) + "%",
            rightContent: "Percentage of frictionless transactions",
            color: _variablesThreeOrangesRate.primaryOrange,
          },
          {
            leftContent: formatNumberFull(acquirer) + "%",
            rightContent: "Of which Acquirer exemptions",
            color: _variablesThreeOrangesRate.primaryOrangeLight,
          },
        ];

        if (domain === "benchmark") {
          setDataFrictionlessCountries(tempData);
          setSideTextRowsFrictionlessCountries(tempRows);
        } else {
          setDataFrictionless(tempData);
          setSideTextRowsFrictionless(tempRows);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingFrictionless(false));
  };

  const getChallengeSuccess = (body, domain) => {
    setIsLoadingChallengeSuccess(true);

    FetchService.post(
      `/api/dashboard/authe/${domain}/challenge-success-rate`,
      body
    )
      .then((res) => {
        let success = res.data.data ? res.data.data : 0;
        let tempData = [
          { name: "Challenge success rate", value: success },
          { name: "Challenge failure rate", value: 100 - success },
        ];
        let tempRows = [
          {
            leftContent: formatNumberFull(success) + "%",
            rightContent: "Challenge success rate",
            color: _variablesThreeOrangesRate.primaryOrange,
          },
        ];

        if (domain === "benchmark") {
          setDataChallengeSuccessCountries(tempData);
          setSideTextRowsChallengeSuccessCountries(tempRows);
        } else {
          setDataChallengeSuccess(tempData);
          setSideTextRowsChallengeSuccess(tempRows);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeSuccess(false));
  };

  const getTransactionsDevice = (body, domain) => {
    setIsLoadingTransactionsDevice(true);

    FetchService.post(
      `/api/dashboard/authe/${domain}/transactions-per-device`,
      body
    )
      .then((res) => {
        let tempData = [];
        let tempBars = [];

        let web = res.data.find((el) => el.label === "Browser-based");
        tempData.push({
          name: "Web",
          value: web ? web.value : "0",
        });
        tempBars.push({
          value: web.auth_rate ? web.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrange,
          text: "Web",
        });

        let app = res.data.find((el) => el.label === "App-based");
        tempData.push({
          name: "App",
          value: app ? app.value : "0",
        });
        tempBars.push({
          value: app ? app.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrangeLight,
          text: "App",
        });

        if (domain === "benchmark") {
          setDataTransactionsDeviceCountries(tempData);
          setSideBarsTransactionsDeviceCountries(tempBars);
        } else {
          setDataTransactionsDevice(tempData);
          setSideBarsTransactionsDevice(tempBars);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsDevice(false));
  };

  const getTransactionsTicketRange = (body, domain) => {
    setIsLoadingTransactionsTicketRange(true);

    FetchService.post(
      `/api/dashboard/authe/${domain}/transactions-by-ticket-range`,
      body
    )
      .then((res) => {
        res.data.forEach((el) => {
          el.label = el.label + ` ${currencySymbol}`;
        });

        domain === "benchmark"
          ? setDataTransactionsTicketRangeCountries(
              sortTicketRanges(res.data, currencySymbol)
            )
          : setDataTransactionsTicketRange(
              sortTicketRanges(res.data, currencySymbol)
            );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsTicketRange(false));
  };

  const getDecline = (body, domain) => {
    setIsLoadingDecline(true);

    FetchService.post(`/api/dashboard/authe/${domain}/decline-reasons`, body)
      .then((res) => {
        let temp = [];
        res.data.forEach((el) => {
          temp.push({
            name: el.label ? el.label : "Unknown",
            size: el.value ? el.value : 0,
            percent: formatNumberFull(el.percent ? el.percent : 0),
          });
        });

        let tempReasons = listDeclineReasons;
        res.data.forEach((reason) => {
          if (!tempReasons.includes(reason.label)) {
            tempReasons.push(reason.label);
          }
        });
        setListDeclineReasons(tempReasons);

        domain === "benchmark"
          ? setDataDeclineCountries(temp)
          : setDataDecline(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDecline(false));
  };

  const getAuthMethods = (body, domain) => {
    setIsLoadingAuthMethods(true);
    FetchService.post(
      `/api/dashboard/authe/${domain}/authentication-methods`,
      body
    )
      .then((res) => {
        let cleanRes = res.data;

        domain === "benchmark"
          ? setApiDataAuthMethodsCountries(cleanRes)
          : setApiDataAuthMethods(cleanRes);

        let tempAuthMethods = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.trx_share ? el.trx_share : 0,
          };
        });
        let tempTopFiveAuthMethods = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.success_rate ? el.success_rate : 0,
          };
        });

        if (domain === "benchmark") {
          setDataAuthMethodsCountries(tempAuthMethods);
          setDataTopFiveAuthMethodsCountries(tempTopFiveAuthMethods);
        } else {
          setDataAuthMethods(tempAuthMethods);
          setDataTopFiveAuthMethods(tempTopFiveAuthMethods);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAuthMethods(false));
  };

  const getChallengeCode = (body, domain) => {
    setIsLoadingChallengeCode(true);
    FetchService.post(`/api/dashboard/authe/${domain}/challenge-code`, body)
      .then((res) => {
        let tempData = res.data.map((el) => {
          return {
            label: el.label ? el.label : "",
            value: el.percent ? el.percent : 0,
          };
        });
        domain === "benchmark"
          ? setDataChallengeCodeCountries(tempData)
          : setDataChallengeCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCode(false));
  };

  const getChallengeCodeForAuthMethod = (body, domain) => {
    setIsLoadingChallengeCodeForAuthMethod(true);
    FetchService.post(
      `/api/dashboard/authe/${domain}/challenge-code-for-auth-method`,
      body
    )
      .then((res) => {
        let tempData = res.data;
        let groupedData = [];

        // group records with the same auth method to display them in the table
        for (let i = 0; i < tempData.length - 2; i += 1) {
          if (tempData[i].auth_method === tempData[i + 1].auth_method) {
            let percent_zerofour = "-";
            let percent_zerofive = "-";

            if (res.data[i].challenge_desc.substring(0, 2) === "04") {
              percent_zerofour = formatNumberRound(res.data[i].percent) + "%";
              percent_zerofive =
                formatNumberRound(res.data[i + 1].percent) + "%";
            } else if (res.data[i].challenge_desc.substring(0, 2) === "05") {
              percent_zerofour =
                formatNumberRound(res.data[i + 1].percent) + "%";
              percent_zerofive = formatNumberRound(res.data[i].percent) + "%";
            }
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: percent_zerofour,
              percent_zerofive: percent_zerofive,
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
            // skip next record since you grouped it with the current one
            i += 1;
          }
          // in case the record has no matching 04 type or 05 type record
          else if (res.data[i].challenge_desc.substring(0, 2) === "04") {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: formatNumberRound(res.data[i].percent) + "%",
              percent_zerofive: "-",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          } else {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: "-",
              percent_zerofive: formatNumberRound(res.data[i].percent) + "%",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          }
        }

        groupedData.sort((a, b) => b.total_trx - a.total_trx);

        domain === "benchmark"
          ? setDataChallengeCodeForAuthMethodCountries(groupedData)
          : setDataChallengeCodeForAuthMethod(groupedData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCodeForAuthMethod(false));
  };

  const get3ds2Versions = (body, domain) => {
    setIsLoading3ds2Versions(true);
    FetchService.post(`/api/dashboard/authe/${domain}/3ds2-versions`, body)
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        let temp3ds2Versions = cleanRes
          .filter((el) => el.trx_share >= 0.1)
          .map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.trx_share ? el.trx_share : 0,
            };
          });

        let temp3ds2VersionsBarChart = cleanRes
          .filter((el) => el.success_rate >= 0.1)
          .map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.success_rate ? el.success_rate : 0,
            };
          });

        if (domain === "benchmark") {
          setData3ds2VersionsCountries(temp3ds2Versions);
          setData3ds2VersionsBarChartCountries(temp3ds2VersionsBarChart);
        } else {
          setData3ds2Versions(temp3ds2Versions);
          setData3ds2VersionsBarChart(temp3ds2VersionsBarChart);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3ds2Versions(false));
  };

  let pages = [
    {
      title: "Overview",
      link: "/authentication/home",
    },
    { title: "Geography", link: "/authentication/geography" },
    { title: "Benchmark", link: "/authentication/benchmark", active: true },
    { title: "Acquirers", link: "/authentication/acquirers" },
    dashboardVersion2 && { title: "Issuers", link: "/authentication/issuers" },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  useEffect(() => {
    setMergedDataTicketRange(
      mergeData(
        dataTransactionsTicketRange,
        dataTransactionsTicketRangeCountries,
        [
          { source: "value", target: "value_merchant" },
          { source: "auth_rate", target: "auth_rate_merchant" },
        ],
        [
          { source: "value", target: "value_peers" },
          { source: "auth_rate", target: "auth_rate_peers" },
        ],
        "label"
      )
    );
  }, [dataTransactionsTicketRange, dataTransactionsTicketRangeCountries]);

  useEffect(() => {
    setMergedDataDeclineReasons(
      mergeData(
        dataDecline,
        dataDeclineCountries,
        [
          { source: "size", target: "size_merchant" },
          { source: "percent", target: "percent_merchant" },
        ],
        [
          { source: "size", target: "size_peers" },
          { source: "percent", target: "percent_peers" },
        ],
        "name"
      )
    );
  }, [dataDecline, dataDeclineCountries]);

  useEffect(() => {
    setMergedDataAuthMethods(
      mergeData(
        apiDataAuthMethods,
        apiDataAuthMethodsCountries,
        [
          { source: "trx_share", target: "trx_share_merchant" },
          { source: "success_rate", target: "success_rate_merchant" },
        ],
        [
          { source: "trx_share", target: "trx_share_peers" },
          { source: "success_rate", target: "success_rate_peers" },
        ],
        "label"
      )
    );
  }, [apiDataAuthMethods, apiDataAuthMethodsCountries]);
  // Visibility settings

  const getVisibility = (graphName) => {
    return filterVisibility(
      "chart",
      settings,
      "Authentication",
      "Benchmark",
      graphName,
      selectedCountries
    );
  };

  const authRateViz = getVisibility("Authentication rate");

  const authSuccessViz = getVisibility("Authentication success");

  const authFailedViz = getVisibility("Authentication failed");

  const frictionlessViz = getVisibility("Frictionless penetration");

  const challengeSuccessViz = getVisibility("Challenge success");

  const versionsShareViz = getVisibility("3DS Versions Share");

  const versionsSuccessViz = getVisibility("3DS Versions Success Rate");

  const trxSharePerDeviceViz = getVisibility("Transaction share per device");

  const trxCountByTicketRangeViz = getVisibility(
    "Transactions count by ticket range"
  );

  const failureReasonsViz = getVisibility("Failure Reasons");

  const trxShareMethodsViz = getVisibility(
    "Transaction share of authentication methods"
  );

  const authRateTop5Viz = getVisibility(
    "Authentication Rate for top 5 authentication methods"
  );

  return (
    <LayoutPageContainer
      title={"Authentication"}
      pages={pages}
      footer="authe"
      alert={alert}
      setAlert={setAlert}
      settings={settings}
    >
      <Refreshing
        conditions={[
          isLoadingKpis,
          isLoadingFrictionless,
          isLoadingChallengeSuccess,
          isLoadingTransactionsDevice,
          isLoadingTransactionsTicketRange,
          isLoadingDecline,
          isLoading3ds2Versions,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      <hr />
      <FilterBoxSticky>
        <LayoutBenchmarkFilter
          options={countries}
          tooltipMerchant={
            "Merchant performance includes the overall performances.<br /><br />" +
            "Authentication rate is calculated as: number of successful authentications divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipPeer={
            "Peer performance includes all the merchants that have your same MCC in any given selected Country.<br /><br />" +
            "Authentication rate is calculated as: number of successful authentications divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          selected={selectedCountries}
          onChange={handleSelectCountry}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">Merchant</Title>
            {authRateViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent: (
                      <KpiProgressChart
                        bar={{
                          value: dataKpis.auth_rate,
                          color: _variablesTwoOranges.primaryOrange,
                        }}
                      />
                    ),
                    righContent: "Authentication rate",
                    autofit: false,
                  },
                ]}
              />
            )}
            {!ukMerchants.includes(merchantName) && authSuccessViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpis.avg_ticket_ok
                        ? formatNumberFull(dataKpis.avg_ticket_ok)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication success
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrange,
                  },
                ]}
              />
            )}

            {!ukMerchants.includes(merchantName) && authFailedViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpis.avg_ticket_ko
                        ? formatNumberFull(dataKpis.avg_ticket_ko)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication failed
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrangeLight,
                  },
                ]}
              />
            )}

            <hr />

            {frictionlessViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Frictionless penetration"}
                  tooltip={
                    "Frictionless transactions do not request any action from client to be authenticated, because flagged as safe.<br /><br />" +
                    "Frictionless penetration measures the incidence of frictionless transactions on total ones, and which of them are flagged as safe by the Acquirer."
                  }
                />
                <McDonutChartDouble
                  data={dataFrictionless}
                  sideTextRows={sideTextRowsFrictionless}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {challengeSuccessViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Challenge success"}
                  tooltip={
                    "Challenge transactions request an action from client to be authenticated, via Strong Customer Authentication (SCA).<br /><br />" +
                    "Challenge success rate measures the number of successful challenge authentication transactions on total number of challenged transactions."
                  }
                />
                <div style={{ marginTop: "2rem" }}>
                  <McProgressChartBlock
                    data={padArray(
                      dataChallengeSuccess,
                      dataChallengeSuccessCountries
                    )}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </div>
            )}

            {dashboardVersion2 && (
              <>
                {versionsShareViz && (
                  <div className="mb-5">
                    <SmallTitleFlex
                      title={"3DS Versions Share"}
                      tooltip={
                        "The shares of card not present transactions split by 3DS versions"
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={data3ds2Versions}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          marginTop: "",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Share</b>
                        <McDonutSideLegend
                          data={data3ds2Versions}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {versionsSuccessViz && (
                  <div className="mb-5">
                    <SmallTitleFlex
                      title={"3DS Versions Success Rate"}
                      tooltip={
                        "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                      }
                    />
                    <McProgressChartBlock
                      data={padArray(
                        data3ds2VersionsBarChart,
                        filterMetrics(
                          data3ds2VersionsBarChartCountries,
                          settings,
                          "Authentication",
                          "Benchmark",
                          "3DS Versions Success Rate",
                          undefined,
                          selectedCountries
                        )
                      )}
                      palette={_variablesTwoOranges}
                    />
                  </div>
                )}
              </>
            )}

            {trxSharePerDeviceViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Transaction share per device"}
                  tooltip={
                    "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                  }
                />
                <McDonutChartSingle
                  data={dataTransactionsDevice}
                  side={"bars"}
                  sideBars={sideBarsTransactionsDevice}
                  sideBarsTitle={"Authentication rate"}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {!ukMerchants.includes(merchantName) &&
              trxCountByTicketRangeViz && (
                <div className="mb-5">
                  <SmallTitleFlex
                    title={"Transactions count by ticket range"}
                    tooltip={
                      "Percentage of transactions per each ticket range.<br /><br />" +
                      "The green line indicates the Authentication rate per each ticket range calculated as: number of successful authentications divided by total transactions."
                    }
                  />
                  <McBarChart
                    data={dataTransactionsTicketRange}
                    dataGuide={{
                      x: "label",
                      barsData: [
                        {
                          dataName: "value",
                          friendlyName:
                            "Percentage of transactions by ticket size",
                        },
                      ],
                      rateData: {
                        dataName: "auth_rate",
                        friendlyName: "Authentication rate",
                      },
                    }}
                    labels={["Percentage (%)", "Rate (%)"]}
                    palette={_variablesThreeOrangesRate}
                    tooltipMeasureUnit="%"
                    rotateTicks
                    labelsPosition="top"
                    labelColor="black"
                  />
                </div>
              )}

            {failureReasonsViz && (
              <>
                <SmallTitleFlex
                  title={"Failure Reasons"}
                  tooltip={
                    "Incidence of each decline reason on total number of failed authentications (card not present channel only).<br /><br />" +
                    "Area of each rectangle indicates the magnitude of each decline reason."
                  }
                />
                <McTreemapChart
                  data={dataDecline}
                  listDeclineReasons={listDeclineReasons}
                  showAbsoluteValue={false}
                />
              </>
            )}

            {dashboardVersion2 && (
              <>
                {trxShareMethodsViz && (
                  <>
                    <hr style={{ marginBottom: "5.6rem" }} />
                    <div>
                      <SmallTitleFlex
                        title={"Transaction share of authentication methods"}
                        tooltip={
                          "Transaction share by different authentication methods"
                        }
                      />
                      <div
                        style={{ display: "flex" }}
                        className="flex-lg-column flex-xl-row"
                      >
                        <McDonutChartSingle
                          data={dataAuthMethods}
                          palette={_variablesSix}
                          tooltipMeasureUnit="%"
                        />
                        <div
                          style={{
                            marginTop: "",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <McDonutSideLegend
                            data={dataAuthMethods}
                            palette={_variablesSix}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {authRateTop5Viz && (
                  <div
                    className="mt-4"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <SmallTitleFlex
                      title={
                        "Authentication Rate for top 5 authentication methods"
                      }
                      tooltip={
                        "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                        "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                      }
                    />
                    <McProgressChartBlock
                      data={dataTopFiveAuthMethods}
                      palette={_variablesSix}
                    />
                    <Button
                      text="Auth method performance by challenge code"
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        //marginLeft:"6rem",
                      }}
                      dataBsToggle="modal"
                      dataBsTarget={"authMethodModal"}
                    />
                  </div>
                )}
              </>
            )}

            {dashboardVersion2 && (
              <Modal
                dataBsTarget="authMethodModal"
                title={<b>Top 5 Authentication Methods by Challenge Code</b>}
                modalBody={
                  <div
                    style={{
                      width: "100%",
                      height: "29rem",
                      display: "flex",
                      gap: "8rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                      }}
                    >
                      <McDonutChartSingle
                        data={dataChallengeCode}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Failed due to ACS Timeout</b>
                        <McDonutSideLegend
                          data={dataChallengeCode.map((el) => {
                            return { name: el.label, value: el.value };
                          })}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <TableAG
                      colDefs={[colsDef[0], colsDef[1]]}
                      rowData={dataChallengeCodeForAuthMethod.slice(0, 5)}
                    />
                  </div>
                }
              >
                <Download
                  section="AutheBenchmark"
                  chartName="Authentication Methods by Challenge Code"
                  data={dataChallengeCodeForAuthMethod}
                  graphType="pie"
                  label={"Authentication Method"}
                  country={selectedCountries}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[
                    { field: "Percent 05", value: "percent_zerofive" },
                    { field: "Percent 04", value: "percent_zerofour" },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </Modal>
            )}
          </>
        }
        rightContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">Peer</Title>
            {authRateViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent: (
                      <KpiProgressChart
                        bar={{
                          value: dataKpisCountries.auth_rate,
                          color: _variablesTwoOranges.primaryOrange,
                        }}
                      />
                    ),
                    righContent: "Authentication rate",
                    autofit: false,
                  },
                ]}
              />
            )}
            {!ukMerchants.includes(merchantName) && authSuccessViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpisCountries.avg_ticket_ok
                        ? formatNumberFull(dataKpisCountries.avg_ticket_ok)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication success
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrange,
                  },
                ]}
              />
            )}

            {!ukMerchants.includes(merchantName) && authFailedViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpisCountries.avg_ticket_ko
                        ? formatNumberFull(dataKpisCountries.avg_ticket_ko)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication failed
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrangeLight,
                  },
                ]}
              />
            )}

            <hr />

            {frictionlessViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Frictionless penetration"}
                  tooltip={
                    "Frictionless transactions do not request any action from client to be authenticated, because flagged as safe.<br /><br />" +
                    "Frictionless penetration measures the incidence of frictionless transactions on total ones, and which of them are flagged as safe by the Acquirer."
                  }
                />
                <McDonutChartDouble
                  data={dataFrictionlessCountries}
                  sideTextRows={sideTextRowsFrictionlessCountries}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {challengeSuccessViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Challenge success"}
                  tooltip={
                    "Challenge transactions request an action from client to be authenticated, via Strong Customer Authentication (SCA).<br /><br />" +
                    "Challenge success rate measures the number of successful challenge authentication transactions on total number of challenged transactions."
                  }
                />
                <div style={{ marginTop: "2rem" }}>
                  <McProgressChartBlock
                    data={padArray(
                      dataChallengeSuccessCountries,
                      dataChallengeSuccess
                    )}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </div>
            )}

            {dashboardVersion2 && (
              <>
                {versionsShareViz && (
                  <div className="mb-5">
                    <SmallTitleFlex
                      title={"3DS Versions Share"}
                      tooltip={
                        "The shares of card not present transactions split by 3DS versions"
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={filterMetrics(
                          data3ds2VersionsCountries,
                          settings,
                          "Authentication",
                          "Benchmark",
                          "3DS Versions Share",
                          undefined,
                          selectedCountries
                        )}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          marginTop: "",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        {data3ds2VersionsCountries.length > 0 && (
                          <b>Transaction Share</b>
                        )}
                        <McDonutSideLegend
                          data={filterMetrics(
                            data3ds2VersionsCountries,
                            settings,
                            "Authentication",
                            "Benchmark",
                            "3DS Versions Share",
                            undefined,
                            selectedCountries
                          )}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {versionsSuccessViz && (
                  <div className="mb-5">
                    <SmallTitleFlex
                      title={"3DS Versions Success Rate"}
                      tooltip={
                        "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                      }
                    />
                    <McProgressChartBlock
                      data={padArray(
                        filterMetrics(
                          data3ds2VersionsBarChartCountries,
                          settings,
                          "Authentication",
                          "Benchmark",
                          "3DS Versions Success Rate",
                          undefined,
                          selectedCountries
                        ),
                        data3ds2VersionsBarChart
                      )}
                      palette={_variablesTwoOranges}
                    />
                  </div>
                )}
              </>
            )}

            {trxSharePerDeviceViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Transaction share per device"}
                  tooltip={
                    "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                  }
                />
                <McDonutChartSingle
                  data={dataTransactionsDeviceCountries}
                  side={"bars"}
                  sideBars={sideBarsTransactionsDeviceCountries}
                  sideBarsTitle={"Authentication rate"}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {!ukMerchants.includes(merchantName) &&
              trxCountByTicketRangeViz && (
                <div className="mb-5">
                  <div className="d-flex justify-content-between">
                    <SmallTitleFlex
                      title={"Transactions count by ticket range"}
                      tooltip={
                        "Percentage of transactions per each ticket range.<br /><br />" +
                        "The green line indicates the Authentication rate per each ticket range calculated as: number of successful authentications divided by total transactions."
                      }
                    />
                    <Download
                      section="AutheBenchmark"
                      chartName="Transactions count by ticket range"
                      data={mergedDataTicketRange}
                      graphType="barChart"
                      label={"Ticket Range"}
                      country={selectedCountries}
                      timeRange={
                        dateLabels[timePeriod[0]] +
                        " - " +
                        dateLabels[timePeriod[1]]
                      }
                      field1={"Merchant Value (%)"}
                      field2={`Peer Value (%)`}
                      field3={"Merchant Auth Rate (%)"}
                      field4={`Peer Auth Rate (%)`}
                      value1={"value_merchant"}
                      value2={"value_peers"}
                      value3={"auth_rate_merchant"}
                      value4={"auth_rate_peers"}
                      iconSize="sm"
                      className="h-8 w-8"
                    />
                  </div>
                  <McBarChart
                    data={dataTransactionsTicketRangeCountries}
                    dataGuide={{
                      x: "label",
                      barsData: [
                        {
                          dataName: "value",
                          friendlyName:
                            "Percentage of transactions by ticket size",
                        },
                      ],
                      rateData: {
                        dataName: "auth_rate",
                        friendlyName: "Authentication rate",
                      },
                    }}
                    labels={["Percentage (%)", "Rate (%)"]}
                    palette={_variablesThreeOrangesRate}
                    tooltipMeasureUnit="%"
                    rotateTicks
                    labelsPosition="top"
                    labelColor="black"
                  />
                </div>
              )}

            {failureReasonsViz && (
              <>
                <div className="d-flex justify-content-between">
                  <SmallTitleFlex
                    title={"Failure Reasons"}
                    tooltip={
                      "Incidence of each decline reason on total number of failed authentications (card not present channel only).<br /><br />" +
                      "Area of each rectangle indicates the magnitude of each decline reason."
                    }
                  />
                  <Download
                    section="AutheBenchmark"
                    chartName="FailureReasons"
                    data={filterMetrics(
                      mergedDataDeclineReasons,
                      settings,
                      "Authentication",
                      "Benchmark",
                      "Failure Reasons",
                      undefined,
                      selectedCountries
                    )}
                    graphType="pie"
                    label={"Failure Reason"}
                    country={selectedCountries}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      { field: "Size Merchant", value: "size_merchant" },
                      { field: "Size Peers", value: "size_peers" },
                      {
                        field: "Percent Merchant (%)",
                        value: "percent_merchant",
                      },
                      { field: "Percent Peers (%)", value: "percent_peers" },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <McTreemapChart
                  data={filterMetrics(
                    dataDeclineCountries,
                    settings,
                    "Authentication",
                    "Benchmark",
                    "Failure Reasons",
                    undefined,
                    selectedCountries
                  )}
                  listDeclineReasons={listDeclineReasons}
                  showAbsoluteValue={false}
                />
              </>
            )}
            {dashboardVersion2 && (
              <>
                {trxShareMethodsViz && (
                  <>
                    <hr style={{ marginBottom: "2rem" }} />

                    <div>
                      <div
                        className="d-flex justify-content-end"
                        style={{ marginBottom: "1rem" }}
                      >
                        <Download
                          section="AutheBenchmark"
                          chartName="AuthMethod"
                          data={filterMetrics(
                            mergedDataAuthMethods,
                            settings,
                            "Authentication",
                            "Benchmark",
                            "Transaction share of authentication methods",
                            undefined,
                            selectedCountries
                          )}
                          graphType="pie"
                          label={"Auth Method"}
                          country={selectedCountries}
                          timeRange={
                            dateLabels[timePeriod[0]] +
                            " - " +
                            dateLabels[timePeriod[1]]
                          }
                          fields={[
                            {
                              field: "Transaction Share Merchant (%)",
                              value: "trx_share_merchant",
                            },
                            {
                              field: "Transaction Share Peers (%)",
                              value: "trx_share_peers",
                            },
                            {
                              field: "Success Rate Merchant (%)",
                              value: "success_rate_merchant",
                            },
                            {
                              field: "Success Rate Peers (%)",
                              value: "success_rate_peers",
                            },
                          ]}
                          iconSize="sm"
                          className="h-8 w-8"
                        />
                      </div>
                      <SmallTitleFlex
                        title={"Transaction share of authentication methods"}
                        tooltip={
                          "Transaction share by different authentication methods"
                        }
                      />
                      <div
                        style={{ display: "flex" }}
                        className="flex-lg-column flex-xl-row"
                      >
                        <McDonutChartSingle
                          data={filterMetrics(
                            dataAuthMethodsCountries,
                            settings,
                            "Authentication",
                            "Benchmark",
                            "Transaction share of authentication methods",
                            undefined,
                            selectedCountries
                          )}
                          palette={_variablesSix}
                          tooltipMeasureUnit="%"
                        />
                        <div
                          style={{
                            marginTop: "",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <McDonutSideLegend
                            data={filterMetrics(
                              dataAuthMethodsCountries,
                              settings,
                              "Authentication",
                              "Benchmark",
                              "Transaction share of authentication methods",
                              undefined,
                              selectedCountries
                            )}
                            palette={_variablesSix}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {authRateTop5Viz && (
                  <div
                    className="mt-4"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <SmallTitleFlex
                      title={
                        "Authentication Rate for top 5 authentication methods"
                      }
                      tooltip={
                        "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                        "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                      }
                    />
                    <McProgressChartBlock
                      data={padArray(
                        filterMetrics(
                          dataTopFiveAuthMethodsCountries,
                          settings,
                          "Authentication",
                          "Benchmark",
                          "Authentication Rate for top 5 authentication methods",
                          undefined,
                          selectedCountries
                        ),
                        dataTopFiveAuthMethods
                      )}
                      palette={_variablesSix}
                    />
                    {dataChallengeCodeCountries.length > 0 ? (
                      <Button
                        text="Auth method performance by challenge code"
                        style={{
                          fontSize: "12pt",
                          padding: "0.5rem",
                          marginTop: "2rem",
                          width: "15rem",
                          //marginLeft:"6rem",
                        }}
                        dataBsToggle="modal"
                        dataBsTarget={"authMethodModalCountries"}
                      />
                    ) : (
                      <div
                        style={{
                          fontSize: "12pt",
                          padding: "0.5rem",
                          marginTop: "2rem",
                          width: "15rem",
                          height: "2.6rem",
                          //marginLeft:"6rem",
                        }}
                      ></div>
                    )}
                  </div>
                )}
              </>
            )}

            {dashboardVersion2 && (
              <Modal
                dataBsTarget="authMethodModalCountries"
                title={<b>Top 5 Authentication Methods by Challenge Code</b>}
                modalBody={
                  <div
                    style={{
                      width: "100%",
                      height: "29rem",
                      display: "flex",
                      gap: "8rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                      }}
                    >
                      <McDonutChartSingle
                        data={dataChallengeCodeCountries}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Failed due to ACS Timeout</b>
                        <McDonutSideLegend
                          data={dataChallengeCodeCountries.map((el) => {
                            return { name: el.label, value: el.value };
                          })}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <TableAG
                      colDefs={[colsDef[0], colsDef[1]]}
                      rowData={filterMetrics(
                        dataChallengeCodeForAuthMethodCountries,
                        settings,
                        "Authentication",
                        "Benchmark",
                        "Authentication Rate for top 5 authentication methods",
                        "auth_method",
                        selectedCountries
                      ).slice(0, 5)}
                    />
                  </div>
                }
              >
                <Download
                  section="AutheBenchmark"
                  chartName="Authentication Methods by Challenge Code"
                  data={filterMetrics(
                    dataChallengeCodeForAuthMethodCountries,
                    settings,
                    "Authentication",
                    "Benchmark",
                    "Authentication Rate for top 5 authentication methods",
                    "auth_method",
                    selectedCountries
                  )}
                  graphType="pie"
                  label={"Authentication Method"}
                  country={selectedCountries}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[
                    { field: "Percent 05", value: "percent_zerofive" },
                    { field: "Percent 04", value: "percent_zerofour" },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </Modal>
            )}
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default AuthenticationBenchmark;
