import { useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";
import TabSwitcher from "../../components/TabSwitcher/TabSwitcher";
import Alert from "../Alert/Alert";
import { filterVisibility } from "../../services/UtilsService";

const LayoutPageContainer = ({
  children,
  title,
  pages,
  footer = "default",
  alert,
  setAlert,
  settings,
}) => {
  const { sidebarExpanded } = useContext(AccountContext);
  pages.forEach((element) => {
    if (!filterVisibility("tab", settings, title, element.title)) {
      pages = pages.filter((el) => el.title !== element.title);
    }
  });

  return (
    <Container footer={footer}>
      <Title>{title}</Title>
      <TabSwitcher pages={pages} />

      <Alert
        styleClass={""}
        alertText={alert.message}
        styleColor={alert.messageType}
        onClick={setAlert}
        paddingLeft={sidebarExpanded}
      />

      {children}
    </Container>
  );
};

export default LayoutPageContainer;
