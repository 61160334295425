import McProgressChart from "./McProgressChart";

const McProgressChartBlock = ({ data, palette }) => {
  const COLORS = Object.values(palette);

  return (
    <>
      {data.map((row, index) => {
        return (
          <div
            key={"bar" + index + row.name}
            style={{ visibility: row.visibility ? row.visibility : "visible" }}
          >
            <div>{row.name ? row.name : "-"}</div>
            <McProgressChart
              bar={{
                value: row.value,
                color: row.index
                  ? COLORS[row.index]
                  : COLORS[index % COLORS.length],
              }}
              small
            />
          </div>
        );
      })}
    </>
  );
};

export default McProgressChartBlock;
