import { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactFitty } from "react-fitty";
import { AccountContext } from "../../services/AccountService";
import SidebarService from "../../services/SidebarService";
import { filterVisibility } from "../../services/UtilsService";
import "./Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    userPermission,
    userEmail,
    logout,
    merchantName,
    acquirerName,
    sidebarExpanded,
    toggleSidebarExpanded,
    hideAuthe,
    settingsConfig,
  } = useContext(AccountContext);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div
      className="container-sidebar d-flex flex-column flex-shrink-0 p-2"
      style={{ width: sidebarExpanded ? "260px" : "72px" }}
    >
      <div className="sticky top-1rem">
        <div className="sidebar-icon-link d-flex justify-content-center align-items-center mb-3">
          <i
            className={"bi bi-list sidebar-hamburger cursor-pointer"}
            onClick={toggleSidebarExpanded}
          ></i>
        </div>
        {sidebarExpanded ? (
          <>
            <Link to={"/"} className="mb-2">
              <img
                src={process.env.PUBLIC_URL + "/imgs/logo.png"}
                className="w-100 p-3"
                alt="Sidebar logo"
              />
            </Link>

            <ul className="nav nav-pills flex-column mb-3">
              <li className="nav-item">
                <div className="nav-link no-hover">
                  <b>{userEmail.includes("demo@") ? "DEMO" : merchantName}</b>
                  <br />
                  {acquirerName ? acquirerName : ""}
                </div>
              </li>
            </ul>

            <ul className="nav nav-pills flex-column mb-3">
              <li className="nav-item">
                <div className="nav-link no-hover d-flex align-items-center flex-shrink-0">
                  <b>
                    {userPermission === "user"
                      ? "REPORTS"
                      : userPermission === "admin"
                      ? "ADMIN"
                      : userPermission === "superadmin"
                      ? "SUPERADMIN"
                      : ""}
                  </b>
                </div>
              </li>
              {SidebarService.map((navItem, navIndex) => {
                if (
                  [
                    "Performance",
                    "Authentication",
                    "Authorization",
                    "Fraud",
                  ].includes(navItem.title)
                ) {
                  if (
                    (hideAuthe && navItem.category === "authentication") ||
                    !filterVisibility("module", settingsConfig, navItem.title)
                  ) {
                    return null;
                  }
                }
                return navItem.auth.includes(userPermission) ? (
                  <li
                    className="nav-item"
                    key={
                      navIndex +
                      navItem.title +
                      "sidebarlinkexp" +
                      Math.random()
                    }
                  >
                    <Link
                      to={navItem.path}
                      className={
                        "nav-link d-flex align-items-center flex-shrink-0 " +
                        (location.pathname.includes(navItem.category) ||
                        (location.pathname === "/" &&
                          navItem.category === "performance") ||
                        (location.pathname === "/" &&
                          navItem.category === "add-user") ||
                        (location.pathname === "/" &&
                          navItem.category === "authentication" &&
                          userPermission === "acquirer")
                          ? "active"
                          : "")
                      }
                    >
                      <span>{navItem.title}</span>
                    </Link>
                  </li>
                ) : (
                  <li
                    className="d-none"
                    key={navIndex + navItem.title + "sidebarlinkhide"}
                  ></li>
                );
              })}
            </ul>

            <ul className="nav nav-pills flex-column mb-auto">
              <li className="nav-item">
                <div className="nav-link no-hover d-flex align-items-center flex-shrink-0">
                  <span>
                    <b>USER</b>
                  </span>
                </div>
              </li>
              <li className="nav-item" onClick={handleLogout}>
                <div className="nav-link d-flex align-items-center flex-shrink-0 cursor-pointer">
                  <span>Logout</span>
                </div>
              </li>
            </ul>
          </>
        ) : (
          <>
            <div className="mb-5">
              <Link to={"/"}>
                <img
                  src={process.env.PUBLIC_URL + "/imgs/logo.png"}
                  className="w-100"
                  alt="Sidebar logo"
                />
              </Link>
            </div>

            {SidebarService.map((navItem, navIndex) => {
              return navItem.auth.includes(userPermission) ? (
                <div
                  className="sidebar-icon-link mb-2"
                  key={
                    navIndex + navItem.shortName + "sidebaricon" + Math.random()
                  }
                >
                  <Link
                    to={navItem.path}
                    className={
                      "nav-link " +
                      (location.pathname.includes(navItem.category) ||
                      (location.pathname === "/" &&
                        navItem.category === "performance") ||
                      (location.pathname === "/" &&
                        navItem.category === "add-user")
                        ? "active"
                        : "")
                    }
                  >
                    <div className="sidebar-icon-container d-flex justify-content-center align-items-center">
                      <img src={navItem.icon} className="sidebar-icon"></img>
                    </div>

                    <span className="sidebar-icon-text text-center">
                      <ReactFitty maxSize={10}>{navItem.shortName}</ReactFitty>
                    </span>
                  </Link>
                </div>
              ) : (
                <div
                  className="d-none"
                  key={navIndex + navItem.title + "sidebariconhide"}
                ></div>
              );
            })}

            <div
              className="sidebar-icon-link mt-5 cursor-pointer"
              onClick={handleLogout}
            >
              <div className="sidebar-icon-container d-flex justify-content-center align-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/imgs/logout.png"}
                  className="sidebar-icon"
                ></img>
              </div>

              <span className="sidebar-icon-text text-center">
                <ReactFitty maxSize={10}>Logout</ReactFitty>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
