import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  convertMonthLabelForApi,
  formatNumberFull,
  formatErrorForAlert,
  titleCase,
  sortTicketRanges,
  assembleBody,
  formatNumberRound,
  compareAndAddDummy,
  mergeData,
  padArray,
  ukMerchants,
  filterVisibility,
  filterMetrics,
} from "../../services/UtilsService";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import McBarChartVertical from "../../components/McCharts/McBarChartVertical";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import LayoutGeographyFilter from "../../components/Layout/LayoutGeographyFilter";
import KpiColumn from "../../components/Kpi/KpiColumn";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import KpiProgressChart from "../../components/Kpi/KpiProgressChart";
import McDonutChartDouble from "../../components/McCharts/McDonutChartDouble";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McBarChart from "../../components/McCharts/McBarChart";
import McTreemapChart from "../../components/McCharts/McTreemapChart";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import Title from "../../components/Title/Title";
import {
  _variablesTwoOranges,
  _variablesThreeOrangesRate,
  _variablesSix,
} from "../../styles/_variables";
import Modal from "../../components/Modal/Modal";
import TableAG from "../../components/Table/TableAG";
import Button from "../../components/Button/Button";
import SmallTitle from "../../components/Title/SmallTitle";
import Switcher from "../../components/Switcher/Switcher";
import Download from "../../components/Button/Download";

const colsDef = [
  { field: "auth_method", headerName: "Authentication Method", flex: 1 },
  {
    headerName: "Challenge Code",
    marryChildren: true,
    children: [
      {
        field: "percent_zerofour",
        headerName: "04",
        flex: 1,
      },
      { field: "percent_zerofive", headerName: "05", flex: 1 },
    ],
  },
  {
    field: "total_trx",
    headerName: "Total Transaction Count",
    flex: 1,
    valueFormatter: (params) => Number(params.value).toLocaleString("en-US"),
  },
];

const AuthenticationGeography = () => {
  // general
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    currency,
    autheDates,
    settingsConfig,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);

  // geography & benchmark
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  // charts
  const [isLoadingTopFifteen, setIsLoadingTopFifteen] = useState(true);
  const [dataTopFifteenTransactions, setDataTopFifteenTransactions] = useState(
    []
  );
  const [dataTopFifteenRate, setDataTopFifteenRate] = useState([]);
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState([]);
  const [isLoadingFrictionless, setIsLoadingFrictionless] = useState(true);
  const [dataFrictionless, setDataFrictionless] = useState([]);
  const [sideTextRowsFrictionless, setSideTextRowsFrictionless] = useState([]);
  const [isLoadingChallengeSuccess, setIsLoadingChallengeSuccess] =
    useState(true);
  const [dataChallengeSuccess, setDataChallengeSuccess] = useState([]);
  const [sideTextRowsChallengeSuccess, setSideTextRowsChallengeSuccess] =
    useState([]);
  const [isLoadingTransactionsDevice, setIsLoadingTransactionsDevice] =
    useState(true);
  const [dataTransactionsDevice, setDataTransactionsDevice] = useState([]);
  const [sideBarsTransactionsDevice, setSideBarsTransactionsDevice] = useState(
    []
  );
  const [
    isLoadingTransactionsTicketRange,
    setIsLoadingTransactionsTicketRange,
  ] = useState(true);
  const [dataTransactionsTicketRange, setDataTransactionsTicketRange] =
    useState([]);
  const [isLoadingDecline, setIsLoadingDecline] = useState(true);
  const [dataDecline, setDataDecline] = useState([]);
  const [listDeclineReasons, setListDeclineReasons] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const currencySymbol = currency === 826 ? "£" : "€";
  const settings = settingsConfig ? settingsConfig : [];

  // charts countries
  const [dataKpisAvg, setDataKpisAvg] = useState([]);
  const [dataKpisCountries, setDataKpisCountries] = useState([]);
  const [dataFrictionlessCountries, setDataFrictionlessCountries] = useState(
    []
  );
  const [
    sideTextRowsFrictionlessCountries,
    setSideTextRowsFrictionlessCountries,
  ] = useState([]);
  const [dataChallengeSuccessCountries, setDataChallengeSuccessCountries] =
    useState([]);
  const [
    sideTextRowsChallengeSuccessCountries,
    setSideTextRowsChallengeSuccessCountries,
  ] = useState([]);
  const [dataTransactionsDeviceCountries, setDataTransactionsDeviceCountries] =
    useState([]);
  const [
    sideBarsTransactionsDeviceCountries,
    setSideBarsTransactionsDeviceCountries,
  ] = useState([]);
  const [
    dataTransactionsTicketRangeCountries,
    setDataTransactionsTicketRangeCountries,
  ] = useState([]);
  const [dataDeclineCountries, setDataDeclineCountries] = useState([]);

  // 3ds2 versions
  const [isLoading3ds2Versions, setIsLoading3ds2Versions] = useState(true);
  const [data3ds2Versions, setData3ds2Versions] = useState([]);
  const [data3ds2VersionsBarChart, setData3ds2VersionsBarChart] = useState([]);
  const [data3ds2VersionsCountries, setData3ds2VersionsCountries] = useState(
    []
  );
  const [
    data3ds2VersionsBarChartCountries,
    setData3ds2VersionsBarChartCountries,
  ] = useState([]);

  // auth methods
  const [isLoadingAuthMethods, setIsLoadingAuthMethods] = useState(true);
  const [dataAuthMethods, setDataAuthMethods] = useState([]);
  const [dataTopFiveAuthMethods, setDataTopFiveAuthMethods] = useState([]);
  const [dataAuthMethodsCountries, setDataAuthMethodsCountries] = useState([]);
  const [dataTopFiveAuthMethodsCountries, setDataTopFiveAuthMethodsCountries] =
    useState([]);
  // challenge code
  const [dataChallengeCode, setDataChallengeCode] = useState([]);
  const [dataChallengeCodeCountries, setDataChallengeCodeCountries] = useState(
    []
  );
  const [isLoadingChallengeCode, setIsLoadingChallengeCode] = useState();
  const [dataChallengeCodeForAuthMethod, setDataChallengeCodeForAuthMethod] =
    useState([]);
  const [
    dataChallengeCodeForAuthMethodCountries,
    setDataChallengeCodeForAuthMethodCountries,
  ] = useState([]);
  const [
    isLoadingChallengeCodeForAuthMethod,
    setIsLoadingChallengeCodeForAuthMethod,
  ] = useState();
  // transaction type
  const [transactionType, setTransactionType] = useState("count");
  //api response
  const [apiDataAuthMethods, setApiDataAuthMethods] = useState([]);
  const [apiDataAuthMethodsCountries, setApiDataAuthMethodsCountries] =
    useState([]);
  //merged data
  const [mergedDataTicketRange, setMergedDataTicketRange] = useState([]);
  const [mergedDataDeclineReasons, setMergedDataDeclineReasons] = useState([]);
  const [mergedDataAuthMethods, setMergedDataAuthMethods] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    if (autheDates) {
      setDateLabels(autheDates);
    } else {
      setAlert({
        message: "Authentication dates not available",
        messageType: "error",
      });
    }
  }, [autheDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBody(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          transactionType === "count" ? false : true,
          undefined,
          currency
        );

        // get countries for select
        getCountries(body);

        getTopFifteen(body);
        getKpis(body);
        getKpisAvg(body);
        getFrictionless(body);
        getChallengeSuccess(body);
        getTransactionsDevice(body);
        getTransactionsTicketRange(body);
        getDecline(body);
        get3ds2Versions(body);
        getAuthMethods(body);
        getChallengeCode(body);
        getChallengeCodeForAuthMethod(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels]);

  // get charts data
  useEffect(() => {
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBody(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        acquirerName,
        transactionType === "count" ? false : true,
        undefined,
        currency
      );

      getTopFifteen(body);
      getKpisAvg(body);
    }
  }, [transactionType]);

  // get charts data based on country
  useEffect(() => {
    if (selectedCountries.length > 0) {
      let body = assembleBodyCountry();

      getKpis(body, true);
      getFrictionless(body, true);
      getChallengeSuccess(body, true);
      getTransactionsDevice(body, true);
      getTransactionsTicketRange(body, true);
      getDecline(body, true);
      get3ds2Versions(body, true);
      getAuthMethods(body, true);
      getChallengeCode(body, true);
      getChallengeCodeForAuthMethod(body, true);
    } else {
      clearChartsCountries();
    }
  }, [timePeriod, dateLabels, selectedCountries]);

  const isLoadingAll = () => {
    setIsLoadingTopFifteen(true);
    setIsLoadingKpis(true);
    setIsLoadingFrictionless(true);
    setIsLoadingChallengeSuccess(true);
    setIsLoadingTransactionsDevice(true);
    setIsLoadingTransactionsTicketRange(true);
    setIsLoadingDecline(true);
    setIsLoading3ds2Versions(true);
    setIsLoadingAuthMethods(true);
    setIsLoadingChallengeCode(true);
    setIsLoadingChallengeCodeForAuthMethod(true);
  };

  const assembleBodyCountry = () => {
    return {
      merchant_name: merchantName,
      start_date: convertMonthLabelForApi(dateLabels[timePeriod[0]]),
      end_date: convertMonthLabelForApi(dateLabels[timePeriod[1]]),
      country: selectedCountries.map((el) => el.value),
      currency: currency,
    };
  };

  const handleSelectCountry = (e) => {
    setSelectedCountries(e);
  };

  const getCountries = (body) => {
    body.only_euro = true;

    FetchService.post("/api/dashboard/geography/authe/top", body)
      .then((res) => {
        let tempCountries = res.data.countries.sort((a, b) =>
          a.localeCompare(b)
        );
        tempCountries = tempCountries.map((el) => {
          return { value: el, label: titleCase(el) };
        });

        setCountries(tempCountries);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const clearChartsCountries = () => {
    setDataKpisCountries([]);
    setDataFrictionlessCountries([]);
    setSideTextRowsFrictionlessCountries([]);
    setDataChallengeSuccessCountries([]);
    setSideTextRowsChallengeSuccessCountries([]);
    setDataTransactionsDeviceCountries([]);
    setSideBarsTransactionsDeviceCountries([]);
    setDataTransactionsTicketRangeCountries([]);
    setDataDeclineCountries([]);
    setData3ds2VersionsCountries([]);
    setData3ds2VersionsBarChartCountries([]);
    setDataAuthMethodsCountries([]);
    setDataTopFiveAuthMethodsCountries([]);
    setDataChallengeCodeCountries([]);
    setDataChallengeCodeForAuthMethodCountries([]);
  };

  const getTopFifteen = (body) => {
    setIsLoadingTopFifteen(true);

    FetchService.post(
      "/api/dashboard/authe/geography/top-15-countries-by-transactions",
      body
    )
      .then((res) => {
        let tempTransactions = [];
        let tempRate = [];

        res.data.forEach((el) => {
          tempTransactions.push({
            country: el.country ? titleCase(el.country) : "",
            value: el.sum_trx ? el.sum_trx / 1000 : 0,
          });
          tempRate.push({
            country: el.country ? titleCase(el.country) : "",
            value: el.auth_rate ? el.auth_rate : 0,
            avg: el.above_average ? el.above_average : 0,
          });
        });

        setDataTopFifteenTransactions(tempTransactions);
        setDataTopFifteenRate(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFifteen(false));
  };

  const getKpis = (body, isCountries) => {
    setIsLoadingKpis(true);

    FetchService.post("/api/dashboard/authe/geography/performance", body)
      .then((res) => {
        isCountries ? setDataKpisCountries(res.data) : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getKpisAvg = (body) => {
    setIsLoadingKpis(true);

    FetchService.post("/api/dashboard/authe/geography/performance", body)
      .then((res) => {
        setDataKpisAvg(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getFrictionless = (body, isCountries) => {
    setIsLoadingFrictionless(true);

    FetchService.post(
      "/api/dashboard/authe/geography/percentage-frictionless-penetration",
      body
    )
      .then((res) => {
        let frictionless = res.data.frictionless ? res.data.frictionless : 0;
        let acquirer = res.data.acquirer_exemption
          ? res.data.acquirer_exemption
          : 0;
        let tempData = [
          [
            { name: "Frictionless transactions", value: frictionless },
            { name: "Challenge transactions", value: 100 - frictionless },
          ],
          [
            { name: "With Acquirer exemptions", value: acquirer },
            { name: "Without Acquirer exemptions", value: 100 - acquirer },
          ],
        ];
        let tempRows = [
          {
            leftContent: formatNumberFull(frictionless) + "%",
            rightContent: "Percentage of frictionless transactions",
            color: _variablesThreeOrangesRate.primaryOrange,
          },
          {
            leftContent: formatNumberFull(acquirer) + "%",
            rightContent: "Of which Acquirer exemptions",
            color: _variablesThreeOrangesRate.primaryOrangeLight,
          },
        ];

        if (isCountries) {
          setDataFrictionlessCountries(tempData);
          setSideTextRowsFrictionlessCountries(tempRows);
        } else {
          setDataFrictionless(tempData);
          setSideTextRowsFrictionless(tempRows);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingFrictionless(false));
  };

  const getChallengeSuccess = (body, isCountries) => {
    setIsLoadingChallengeSuccess(true);

    FetchService.post(
      "/api/dashboard/authe/geography/challenge-success-rate",
      body
    )
      .then((res) => {
        let success = res.data.data ? res.data.data : 0;
        let tempData = [
          { name: "Challenge success rate", value: success },
          { name: "Challenge failure rate", value: 100 - success },
        ];
        let tempRows = [
          {
            leftContent: formatNumberFull(success) + "%",
            rightContent: "Challenge success rate",
            color: _variablesThreeOrangesRate.primaryOrange,
          },
        ];

        if (isCountries) {
          setDataChallengeSuccessCountries(tempData);
          setSideTextRowsChallengeSuccessCountries(tempRows);
        } else {
          setDataChallengeSuccess(tempData);
          setSideTextRowsChallengeSuccess(tempRows);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeSuccess(false));
  };

  const getTransactionsDevice = (body, isCountries) => {
    setIsLoadingTransactionsDevice(true);

    FetchService.post(
      "/api/dashboard/authe/geography/transactions-per-device",
      body
    )
      .then((res) => {
        let tempData = [];
        let tempBars = [];

        let web = res.data.find((el) => el.label === "Browser-based");
        tempData.push({
          name: "Web",
          value: web != undefined ? web.value : "0",
        });
        tempBars.push({
          value: web != undefined ? web.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrange,
          text: "Web",
        });
        let app = res.data.find((el) => el.label === "App-based");
        tempData.push({
          name: "App",
          value: app ? app.value : "0",
        });
        tempBars.push({
          value: app ? app.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrangeLight,
          text: "App",
        });

        if (isCountries) {
          setDataTransactionsDeviceCountries(tempData);
          setSideBarsTransactionsDeviceCountries(tempBars);
        } else {
          setDataTransactionsDevice(tempData);
          setSideBarsTransactionsDevice(tempBars);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsDevice(false));
  };

  const getTransactionsTicketRange = (body, isCountries) => {
    setIsLoadingTransactionsTicketRange(true);

    FetchService.post(
      "/api/dashboard/authe/geography/transactions-by-ticket-range",
      body
    )
      .then((res) => {
        res.data.forEach((el) => {
          el.label = el.label ? el.label + ` ${currencySymbol}` : "";
          el.value = el.value ? el.value : 0;
          el.auth_rate = el.auth_rate ? el.auth_rate : 0;
        });

        isCountries
          ? setDataTransactionsTicketRangeCountries(
              sortTicketRanges(res.data, currencySymbol)
            )
          : setDataTransactionsTicketRange(
              sortTicketRanges(res.data, currencySymbol)
            );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsTicketRange(false));
  };

  const getDecline = (body, isCountries) => {
    setIsLoadingDecline(true);

    FetchService.post("/api/dashboard/authe/geography/decline-reasons", body)
      .then((res) => {
        let temp = [];
        res.data.forEach((el) => {
          temp.push({
            name: el.label ? el.label : "Unknown",
            size: el.value ? el.value : 0,
            percent: formatNumberFull(el.percent ? el.percent : 0),
          });
        });

        let tempReasons = listDeclineReasons;
        res.data.forEach((reason) => {
          if (!tempReasons.includes(reason.label)) {
            tempReasons.push(reason.label);
          }
        });
        setListDeclineReasons(tempReasons);

        isCountries ? setDataDeclineCountries(temp) : setDataDecline(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDecline(false));
  };

  const getAuthMethods = (body, isCountries) => {
    setIsLoadingAuthMethods(true);
    FetchService.post(
      "/api/dashboard/authe/geography/authentication-methods",
      body
    )
      .then((res) => {
        let cleanRes = res.data;

        isCountries
          ? setApiDataAuthMethodsCountries(cleanRes)
          : setApiDataAuthMethods(cleanRes);

        let tempAuthMethods = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.trx_share ? el.trx_share : 0,
          };
        });
        let tempTopFiveAuthMethods = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.success_rate ? el.success_rate : 0,
          };
        });

        if (isCountries) {
          setDataAuthMethodsCountries(tempAuthMethods);
          setDataTopFiveAuthMethodsCountries(tempTopFiveAuthMethods);
        } else {
          setDataAuthMethods(tempAuthMethods);
          setDataTopFiveAuthMethods(tempTopFiveAuthMethods);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAuthMethods(false));
  };

  const getChallengeCode = (body, isCountries) => {
    setIsLoadingChallengeCode(true);
    FetchService.post("/api/dashboard/authe/geography/challenge-code", body)
      .then((res) => {
        let tempData = res.data.map((el) => {
          return {
            label: el.label ? el.label : "",
            value: el.percent ? el.percent : 0,
          };
        });
        isCountries
          ? setDataChallengeCodeCountries(tempData)
          : setDataChallengeCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCode(false));
  };

  const getChallengeCodeForAuthMethod = (body, isCountries) => {
    setIsLoadingChallengeCodeForAuthMethod(true);
    FetchService.post(
      "/api/dashboard/authe/geography/challenge-code-for-auth-method",
      body
    )
      .then((res) => {
        let tempData = res.data;
        let groupedData = [];

        // group records with the same auth method to display them in the table
        for (let i = 0; i < tempData.length - 2; i += 1) {
          if (tempData[i].auth_method === tempData[i + 1].auth_method) {
            let percent_zerofour = "-";
            let percent_zerofive = "-";

            if (res.data[i].challenge_desc.substring(0, 2) === "04") {
              percent_zerofour = formatNumberRound(res.data[i].percent) + "%";
              percent_zerofive =
                formatNumberRound(res.data[i + 1].percent) + "%";
            } else if (res.data[i].challenge_desc.substring(0, 2) === "05") {
              percent_zerofour =
                formatNumberRound(res.data[i + 1].percent) + "%";
              percent_zerofive = formatNumberRound(res.data[i].percent) + "%";
            }
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: percent_zerofour,
              percent_zerofive: percent_zerofive,
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
            // skip next record since you grouped it with the current one
            i += 1;
          }
          // in case the record has no matching 04 type or 05 type record
          else if (res.data[i].challenge_desc.substring(0, 2) === "04") {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: formatNumberRound(res.data[i].percent) + "%",
              percent_zerofive: "-",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          } else {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: "-",
              percent_zerofive: formatNumberRound(res.data[i].percent) + "%",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          }
        }

        groupedData.sort((a, b) => b.total_trx - a.total_trx);

        isCountries
          ? setDataChallengeCodeForAuthMethodCountries(groupedData)
          : setDataChallengeCodeForAuthMethod(groupedData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCodeForAuthMethod(false));
  };

  const get3ds2Versions = (body, isCountries) => {
    setIsLoading3ds2Versions(true);
    FetchService.post("/api/dashboard/authe/geography/3ds2-versions", body)
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        let temp3ds2Versions = cleanRes
          .filter((el) => el.trx_share >= 0.1)
          .map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.trx_share ? el.trx_share : 0,
            };
          });

        let temp3ds2VersionsBarChart = cleanRes
          .filter((el) => el.success_rate >= 0.1)
          .map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.success_rate ? el.success_rate : 0,
            };
          });

        if (isCountries) {
          setData3ds2VersionsCountries(temp3ds2Versions);
          setData3ds2VersionsBarChartCountries(temp3ds2VersionsBarChart);
        } else {
          setData3ds2Versions(temp3ds2Versions);
          setData3ds2VersionsBarChart(temp3ds2VersionsBarChart);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3ds2Versions(false));
  };

  let pages = [
    {
      title: "Overview",
      link: "/authentication/home",
    },
    { title: "Geography", link: "/authentication/geography", active: true },
    { title: "Benchmark", link: "/authentication/benchmark" },
    { title: "Acquirers", link: "/authentication/acquirers" },
    dashboardVersion2 && { title: "Issuers", link: "/authentication/issuers" },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  function mergeTopFifteen(array1, array2) {
    return array1.map((item1) => {
      const item2 = array2.find((item2) => item2.country === item1.country);
      return item2
        ? {
            ...item1,
            authRate: item2.value,
          }
        : item1;
    });
  }

  useEffect(() => {
    setMergedDataTicketRange(
      mergeData(
        dataTransactionsTicketRange,
        dataTransactionsTicketRangeCountries,
        [
          { source: "value", target: "value_all" },
          { source: "auth_rate", target: "auth_rate_all" },
        ],
        [
          { source: "value", target: "value_country" },
          { source: "auth_rate", target: "auth_rate_country" },
        ],
        "label"
      )
    );
  }, [dataTransactionsTicketRange, dataTransactionsTicketRangeCountries]);

  useEffect(() => {
    setMergedDataDeclineReasons(
      mergeData(
        dataDecline,
        dataDeclineCountries,
        [
          { source: "size", target: "size_all" },
          { source: "percent", target: "percent_all" },
        ],
        [
          { source: "size", target: "size_country" },
          { source: "percent", target: "percent_country" },
        ],
        "name"
      )
    );
  }, [dataDecline, dataDeclineCountries]);

  useEffect(() => {
    setMergedDataAuthMethods(
      mergeData(
        apiDataAuthMethods,
        apiDataAuthMethodsCountries,
        [
          { source: "trx_share", target: "trx_share_all" },
          { source: "success_rate", target: "success_rate_all" },
        ],
        [
          { source: "trx_share", target: "trx_share_country" },
          { source: "success_rate", target: "success_rate_country" },
        ],
        "label"
      )
    );
  }, [apiDataAuthMethods, apiDataAuthMethodsCountries]);

  // Visibility settings

  const getVisibility = (graphName) => {
    return filterVisibility(
      "chart",
      settings,
      "Authentication",
      "Geography",
      graphName,
      selectedCountries
    );
  };

  const top15Viz = getVisibility("Top 15 countries by transactions");

  const authRateViz = getVisibility("Authentication rate");

  const authSuccessViz = getVisibility("Authentication success");

  const authFailedViz = getVisibility("Authentication failed");

  const frictionlessViz = getVisibility("Frictionless penetration");

  const challengeSuccessViz = getVisibility("Challenge success");

  const versionsShareViz = getVisibility("3DS Versions Share");

  const versionsSuccessViz = getVisibility("3DS Versions Success Rate");

  const trxSharePerDeviceViz = getVisibility("Transaction share per device");

  const trxCountByTicketRangeViz = getVisibility(
    "Transactions count by ticket range"
  );

  const failureReasonsViz = getVisibility("Failure Reasons");

  const trxShareMethodsViz = getVisibility(
    "Transaction share of authentication methods"
  );

  const authRateTop5Viz = getVisibility(
    "Authentication Rate for top 5 authentication methods"
  );

  return (
    <LayoutPageContainer
      title={"Authentication"}
      pages={pages}
      footer="authe"
      alert={alert}
      setAlert={setAlert}
      settings={settings}
    >
      <Refreshing
        conditions={[
          isLoadingTopFifteen,
          isLoadingKpis,
          isLoadingFrictionless,
          isLoadingChallengeSuccess,
          isLoadingTransactionsDevice,
          isLoadingTransactionsTicketRange,
          isLoadingDecline,
          isLoading3ds2Versions,
          isLoadingAuthMethods,
          isLoadingChallengeCode,
          isLoadingChallengeCodeForAuthMethod,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />
      {/* 
      <FilterBar>
        <LayoutCardFilter
          options={[
            { value: "credit", text: "All products" },
            { value: "credit", text: "Credit" },
            { value: "credit", text: "Debit" },
            { value: "credit", text: "Prepaid" },
          ]}
        />
      </FilterBar> */}

      <hr />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
            },
          ]}
        />
      </div> */}

      {top15Viz && (
        <>
          <div className="d-flex justify-content-between">
            <SmallTitleFlex
              title={"Top 15 countries by transactions"}
              tooltip={
                "Top 15 issuing countries listed in descending order by the number of transactions in the authentication flow.<br /><br />" +
                "For each issuing country, the graph on the right shows the corresponding Authentication rate, calculated as: number of successful authentications divided by total transactions."
              }
            />
            <Download
              section="AutheGeography"
              chartName="Top 15 countries by transactions"
              data={mergeTopFifteen(
                dataTopFifteenTransactions,
                dataTopFifteenRate
              )}
              graphType="barChart"
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              label={"Country"}
              field1={transactionType === "count" ? "Transactions" : "Volumes"}
              value1={"value"}
              field2={"Authentication Rate (%)"}
              value2={"authRate"}
              iconSize="sm"
              className="h-8 w-8"
            />
          </div>
          {/* <Switcher
        pages={[
          {
            title: "Transactions",
            onClick: () => {
              setTransactionType("count");
            },
          },
          {
            title: "Volumes",
            onClick: () => {
              setTransactionType("volume");
            },
          },
        ]}
      /> */}
          <LayoutTwoColumns
            leftContent={
              <>
                <div className="text-center mb-3">
                  {transactionType === "count"
                    ? "Transactions (K)"
                    : `Volumes (K${currencySymbol})`}
                </div>
                <McBarChartVertical
                  background={false}
                  data={dataTopFifteenTransactions}
                  dataGuide={{
                    y: "country",
                    barsData:
                      transactionType === "count"
                        ? [{ dataName: "value", friendlyName: "Transactions" }]
                        : [{ dataName: "value", friendlyName: "Volumes" }],
                  }}
                  tooltipMeasureUnit={
                    transactionType === "count" ? " K" : ` K${currencySymbol}`
                  }
                />
              </>
            }
            rightContent={
              <>
                <div className="text-center mb-3">Authentication rate (%)</div>
                <McBarChartVertical
                  data={dataTopFifteenRate}
                  dataGuide={{
                    y: "country",
                    barsData: [{ dataName: "value", friendlyName: "Rate" }],
                  }}
                  labels={["Rate (%)"]}
                  legend
                  tooltipMeasureUnit="%"
                  avg={dataKpisAvg.auth_rate}
                />
              </>
            }
          />

          <hr />
        </>
      )}

      <FilterBoxSticky>
        <LayoutGeographyFilter
          options={countries}
          tooltipOverall={
            "Overall performance includes all the issuing countries.<br /><br />" +
            "Authentication rate is calculated as: number of successful authentications divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipCountry={
            "Country performance includes the selected countries.<br /><br />" +
            "Authentication rate is calculated as: number of successful authentications divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          selected={selectedCountries}
          onChange={handleSelectCountry}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">
              Overall performance
            </Title>
            {authRateViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent: (
                      <KpiProgressChart
                        bar={{
                          value: dataKpis.auth_rate,
                          color: _variablesTwoOranges.primaryOrange,
                        }}
                      />
                    ),
                    righContent: "Authentication rate",
                    autofit: false,
                  },
                ]}
              />
            )}
            {!ukMerchants.includes(merchantName) && authSuccessViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpis.avg_ticket_ok
                        ? formatNumberFull(dataKpis.avg_ticket_ok)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication success
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrange,
                  },
                ]}
              />
            )}

            {!ukMerchants.includes(merchantName) && authFailedViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpis.avg_ticket_ko
                        ? formatNumberFull(dataKpis.avg_ticket_ko)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication failed
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrangeLight,
                  },
                ]}
              />
            )}

            <hr />

            {frictionlessViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Frictionless penetration"}
                  tooltip={
                    "Frictionless transactions do not request any action from client to be authenticated, because flagged as safe.<br /><br />" +
                    "Frictionless penetration measures the incidence of frictionless transactions on total ones, and which of them are flagged as safe by the Acquirer."
                  }
                />
                <McDonutChartDouble
                  data={dataFrictionless}
                  sideTextRows={sideTextRowsFrictionless}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {challengeSuccessViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Challenge success"}
                  tooltip={
                    "Challenge transactions request an action from client to be authenticated, via Strong Customer Authentication (SCA).<br /><br />" +
                    "Challenge success rate measures the number of successful challenge authentication transactions on total number of challenged transactions."
                  }
                />
                <div style={{ marginTop: "2rem" }}>
                  <McProgressChartBlock
                    data={padArray(
                      dataChallengeSuccess,
                      dataChallengeSuccessCountries
                    )}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </div>
            )}

            {dashboardVersion2 && (
              <>
                {versionsShareViz && (
                  <div className="mb-5">
                    <SmallTitleFlex
                      title={"3DS Versions Share"}
                      tooltip={
                        "The shares of card not present transactions split by 3DS versions"
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={filterMetrics(
                          data3ds2Versions,
                          settings,
                          "Authentication",
                          "Geography",
                          "3DS Versions Share",
                          undefined,
                          selectedCountries
                        )}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          marginTop: "",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Share</b>
                        <McDonutSideLegend
                          data={filterMetrics(
                            data3ds2Versions,
                            settings,
                            "Authentication",
                            "Geography",
                            "3DS Versions Share",
                            undefined,
                            selectedCountries
                          )}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {versionsSuccessViz && (
                  <div className="mb-5">
                    <SmallTitleFlex
                      title={"3DS Versions Success Rate"}
                      tooltip={
                        "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                      }
                    />
                    <McProgressChartBlock
                      data={padArray(
                        filterMetrics(
                          data3ds2VersionsBarChart,
                          settings,
                          "Authentication",
                          "Geography",
                          "3DS Versions Success Rate",
                          undefined,
                          selectedCountries
                        ),
                        filterMetrics(
                          data3ds2VersionsBarChartCountries,
                          settings,
                          "Authentication",
                          "Geography",
                          "3DS Versions Success Rate",
                          undefined,
                          selectedCountries
                        )
                      )}
                      palette={_variablesTwoOranges}
                    />
                  </div>
                )}
              </>
            )}

            {trxSharePerDeviceViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Transaction share per device"}
                  tooltip={
                    "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                  }
                />
                <McDonutChartSingle
                  data={dataTransactionsDevice}
                  side={"bars"}
                  sideBars={sideBarsTransactionsDevice}
                  sideBarsTitle={"Authentication rate"}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {!ukMerchants.includes(merchantName) &&
              trxCountByTicketRangeViz && (
                <div className="mb-5">
                  <SmallTitleFlex
                    title={"Transactions count by ticket range"}
                    tooltip={
                      "Percentage of transactions per each ticket range.<br /><br />" +
                      "The green line indicates the Authentication rate per each ticket range calculated as: number of successful authentications divided by total transactions."
                    }
                  />
                  <McBarChart
                    data={dataTransactionsTicketRange}
                    dataGuide={{
                      x: "label",
                      barsData: [
                        {
                          dataName: "value",
                          friendlyName:
                            "Percentage of transactions by ticket size",
                        },
                      ],
                      rateData: {
                        dataName: "auth_rate",
                        friendlyName: "Authentication rate",
                      },
                    }}
                    labels={["Percentage (%)", "Rate (%)"]}
                    palette={_variablesThreeOrangesRate}
                    tooltipMeasureUnit="%"
                    rotateTicks
                    labelsPosition="top"
                    labelColor="black"
                  />
                </div>
              )}

            {failureReasonsViz && (
              <>
                <SmallTitleFlex
                  title={"Failure Reasons"}
                  tooltip={
                    "Incidence of each decline reason on total number of failed authentications (card not present channel only).<br /><br />" +
                    "Area of each rectangle indicates the magnitude of each decline reason."
                  }
                />
                <McTreemapChart
                  data={filterMetrics(
                    dataDecline,
                    settings,
                    "Authentication",
                    "Geography",
                    "Failure Reasons",
                    undefined,
                    selectedCountries
                  )}
                  listDeclineReasons={listDeclineReasons}
                />
              </>
            )}

            {dashboardVersion2 && (
              <>
                {trxShareMethodsViz && (
                  <>
                    <hr style={{ marginBottom: "5.6rem" }} />
                    <div>
                      <SmallTitleFlex
                        title={"Transaction share of authentication methods"}
                        tooltip={
                          "Transaction share by different authentication methods"
                        }
                      />
                      <div
                        style={{ display: "flex" }}
                        className="flex-lg-column flex-xl-row"
                      >
                        <McDonutChartSingle
                          data={filterMetrics(
                            dataAuthMethods,
                            settings,
                            "Authentication",
                            "Geography",
                            "Transaction share of authentication methods",
                            undefined,
                            selectedCountries
                          )}
                          palette={_variablesSix}
                          tooltipMeasureUnit="%"
                        />
                        <div
                          style={{
                            marginTop: "",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <McDonutSideLegend
                            data={filterMetrics(
                              dataAuthMethods,
                              settings,
                              "Authentication",
                              "Geography",
                              "Transaction share of authentication methods",
                              undefined,
                              selectedCountries
                            )}
                            palette={_variablesSix}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {authRateTop5Viz && (
                  <div
                    className="mt-4"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <SmallTitleFlex
                      title={
                        "Authentication Rate for top 5 authentication methods"
                      }
                      tooltip={
                        "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                        "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                      }
                    />
                    <McProgressChartBlock
                      data={filterMetrics(
                        dataTopFiveAuthMethods,
                        settings,
                        "Authentication",
                        "Geography",
                        "Authentication Rate for top 5 authentication methods",
                        undefined,
                        selectedCountries
                      )}
                      palette={_variablesSix}
                    />
                    <Button
                      text="Auth method performance by challenge code"
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        //marginLeft:"6rem",
                      }}
                      dataBsToggle="modal"
                      dataBsTarget={"authMethodModal"}
                    />
                  </div>
                )}
              </>
            )}

            {dashboardVersion2 && (
              <Modal
                dataBsTarget="authMethodModal"
                title={<b>Top 5 Authentication Methods by Challenge Code</b>}
                modalBody={
                  <div
                    style={{
                      width: "100%",
                      height: "29rem",
                      display: "flex",
                      gap: "8rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                      }}
                    >
                      <McDonutChartSingle
                        data={dataChallengeCode}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Failed due to ACS Timeout</b>
                        <McDonutSideLegend
                          data={dataChallengeCode.map((el) => {
                            return { name: el.label, value: el.value };
                          })}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <TableAG
                      colDefs={colsDef}
                      rowData={filterMetrics(
                        dataChallengeCodeForAuthMethod,
                        settings,
                        "Authentication",
                        "Geography",
                        "Authentication Rate for top 5 authentication methods",
                        "auth_method",
                        selectedCountries
                      ).slice(0, 5)}
                    />
                  </div>
                }
              >
                <Download
                  section="AutheGeography"
                  chartName="Authentication Methods by Challenge Code"
                  data={filterMetrics(
                    dataChallengeCodeForAuthMethod,
                    settings,
                    "Authentication",
                    "Geography",
                    "Authentication Rate for top 5 authentication methods",
                    "auth_method",
                    selectedCountries
                  )}
                  graphType="pie"
                  label={"Authentication Method"}
                  country={selectedCountries}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[
                    { field: "Percent 05", value: "percent_zerofive" },
                    { field: "Percent 04", value: "percent_zerofour" },
                    { field: "Total", value: "total_trx" },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </Modal>
            )}
          </>
        }
        rightContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">
              Country performance
            </Title>
            {authRateViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent: (
                      <KpiProgressChart
                        bar={{
                          value: dataKpisCountries.auth_rate,
                          color: _variablesTwoOranges.primaryOrange,
                        }}
                      />
                    ),
                    righContent: "Authentication rate",
                    autofit: false,
                  },
                ]}
              />
            )}
            {!ukMerchants.includes(merchantName) && authSuccessViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpisCountries.avg_ticket_ok
                        ? formatNumberFull(dataKpisCountries.avg_ticket_ok)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication success
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrange,
                  },
                ]}
              />
            )}
            {!ukMerchants.includes(merchantName) && authFailedViz && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpisCountries.avg_ticket_ko
                        ? formatNumberFull(dataKpisCountries.avg_ticket_ko)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication failed
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrangeLight,
                  },
                ]}
              />
            )}

            <hr />

            {frictionlessViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Frictionless penetration"}
                  tooltip={
                    "Frictionless transactions do not request any action from client to be authenticated, because flagged as safe.<br /><br />" +
                    "Frictionless penetration measures the incidence of frictionless transactions on total ones, and which of them are flagged as safe by the Acquirer."
                  }
                />
                <McDonutChartDouble
                  data={dataFrictionlessCountries}
                  sideTextRows={sideTextRowsFrictionlessCountries}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {challengeSuccessViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Challenge success"}
                  tooltip={
                    "Challenge transactions request an action from client to be authenticated, via Strong Customer Authentication (SCA).<br /><br />" +
                    "Challenge success rate measures the number of successful challenge authentication transactions on total number of challenged transactions."
                  }
                />
                <div style={{ marginTop: "2rem" }}>
                  <McProgressChartBlock
                    data={padArray(
                      dataChallengeSuccessCountries,
                      dataChallengeSuccess
                    )}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </div>
            )}

            {dashboardVersion2 && (
              <>
                {versionsShareViz && (
                  <div className="mb-5">
                    <SmallTitleFlex
                      title={"3DS Versions Share"}
                      tooltip={
                        "The shares of card not present transactions split by 3DS versions"
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={filterMetrics(
                          data3ds2VersionsCountries,
                          settings,
                          "Authentication",
                          "Geography",
                          "3DS Versions Share",
                          undefined,
                          selectedCountries
                        )}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          marginTop: "",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        {data3ds2VersionsCountries.length > 0 && (
                          <b>Transaction Share</b>
                        )}
                        <McDonutSideLegend
                          data={filterMetrics(
                            data3ds2VersionsCountries,
                            settings,
                            "Authentication",
                            "Geography",
                            "3DS Versions Share",
                            undefined,
                            selectedCountries
                          )}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {versionsSuccessViz && (
                  <div className="mb-5">
                    <SmallTitleFlex
                      title={"3DS Versions Success Rate"}
                      tooltip={
                        "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                      }
                    />
                    <McProgressChartBlock
                      data={padArray(
                        filterMetrics(
                          data3ds2VersionsBarChartCountries,
                          settings,
                          "Authentication",
                          "Geography",
                          "3DS Versions Success Rate",
                          undefined,
                          selectedCountries
                        ),
                        filterMetrics(
                          data3ds2VersionsBarChart,
                          settings,
                          "Authentication",
                          "Geography",
                          "3DS Versions Success Rate",
                          undefined,
                          selectedCountries
                        )
                      )}
                      palette={_variablesTwoOranges}
                    />
                  </div>
                )}
              </>
            )}

            {trxSharePerDeviceViz && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Transaction share per device"}
                  tooltip={
                    "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                  }
                />
                <McDonutChartSingle
                  data={dataTransactionsDeviceCountries}
                  side={"bars"}
                  sideBars={sideBarsTransactionsDeviceCountries}
                  sideBarsTitle={"Authentication rate"}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
              </div>
            )}

            {!ukMerchants.includes(merchantName) &&
              trxCountByTicketRangeViz && (
                <div className="mb-5">
                  <div className="d-flex justify-content-between">
                    <SmallTitleFlex
                      title={"Transactions count by ticket range"}
                      tooltip={
                        "Percentage of transactions per each ticket range.<br /><br />" +
                        "The green line indicates the Authentication rate per each ticket range calculated as: number of successful authentications divided by total transactions."
                      }
                    />
                    <Download
                      disabled={selectedCountries.length === 0}
                      section="AutheGeography"
                      chartName="Transactions count by ticket range"
                      data={mergedDataTicketRange}
                      graphType="barChart"
                      label={"Ticket Range"}
                      country={selectedCountries}
                      timeRange={
                        dateLabels[timePeriod[0]] +
                        " - " +
                        dateLabels[timePeriod[1]]
                      }
                      field1={"Overall Value (%)"}
                      field2={`Country Value (%)`}
                      field3={"Overall Auth Rate (%)"}
                      field4={`Country Auth Rate (%)`}
                      value1={"value_all"}
                      value2={"value_country"}
                      value3={"auth_rate_all"}
                      value4={"auth_rate_country"}
                      iconSize="sm"
                      className="h-8 w-8"
                    />
                  </div>
                  <McBarChart
                    data={dataTransactionsTicketRangeCountries}
                    dataGuide={{
                      x: "label",
                      barsData: [
                        {
                          dataName: "value",
                          friendlyName:
                            "Percentage of transactions by ticket size",
                        },
                      ],
                      rateData: {
                        dataName: "auth_rate",
                        friendlyName: "Authentication rate",
                      },
                    }}
                    labels={["Percentage (%)", "Rate (%)"]}
                    palette={_variablesThreeOrangesRate}
                    tooltipMeasureUnit="%"
                    rotateTicks
                    labelsPosition="top"
                    labelColor="black"
                  />
                </div>
              )}

            {failureReasonsViz && (
              <>
                <div className="d-flex justify-content-between">
                  <SmallTitleFlex
                    title={"Failure Reasons"}
                    tooltip={
                      "Incidence of each decline reason on total number of failed authentications (card not present channel only).<br /><br />" +
                      "Area of each rectangle indicates the magnitude of each decline reason."
                    }
                  />
                  <Download
                    disabled={selectedCountries.length === 0}
                    section="AutheGeography"
                    chartName="FailureReasons"
                    data={filterMetrics(
                      mergedDataDeclineReasons,
                      settings,
                      "Authentication",
                      "Geography",
                      "Failure Reasons",
                      undefined,
                      selectedCountries
                    )}
                    graphType="pie"
                    label={"Failure Reason"}
                    country={selectedCountries}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      { field: "Size All", value: "size_all" },
                      { field: "Size Country", value: "size_country" },
                      { field: "Percent All (%)", value: "percent_all" },
                      {
                        field: "Percent Country (%)",
                        value: "percent_country",
                      },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <McTreemapChart
                  data={filterMetrics(
                    dataDeclineCountries,
                    settings,
                    "Authentication",
                    "Geography",
                    "Failure Reasons",
                    undefined,
                    selectedCountries
                  )}
                  listDeclineReasons={listDeclineReasons}
                />
              </>
            )}
            {dashboardVersion2 && (
              <>
                {trxShareMethodsViz && (
                  <>
                    <hr style={{ marginBottom: "2rem" }} />

                    <div>
                      <div
                        className="d-flex justify-content-end"
                        style={{ marginBottom: "1rem" }}
                      >
                        <Download
                          disabled={selectedCountries.length === 0}
                          section="AutheGeography"
                          chartName="AuthMethod"
                          data={filterMetrics(
                            mergedDataAuthMethods,
                            settings,
                            "Authentication",
                            "Geography",
                            "Transaction share of authentication methods",
                            undefined,
                            selectedCountries
                          )}
                          graphType="pie"
                          label={"Auth Method"}
                          country={selectedCountries}
                          timeRange={
                            dateLabels[timePeriod[0]] +
                            " - " +
                            dateLabels[timePeriod[1]]
                          }
                          fields={[
                            {
                              field: "Transaction Share All (%)",
                              value: "trx_share_all",
                            },
                            {
                              field: "Transaction Share Country (%)",
                              value: "trx_share_country",
                            },
                            {
                              field: "Success Rate All (%)",
                              value: "success_rate_all",
                            },
                            {
                              field: "Success Rate Country (%)",
                              value: "success_rate_country",
                            },
                          ]}
                          iconSize="sm"
                          className="h-8 w-8"
                        />
                      </div>
                      <SmallTitleFlex
                        title={"Transaction share of authentication methods"}
                        tooltip={
                          "Transaction share by different authentication methods"
                        }
                      />

                      <div
                        style={{ display: "flex" }}
                        className="flex-lg-column flex-xl-row"
                      >
                        <McDonutChartSingle
                          data={compareAndAddDummy(
                            filterMetrics(
                              dataAuthMethodsCountries,
                              settings,
                              "Authentication",
                              "Geography",
                              "Transaction share of authentication methods",
                              undefined,
                              selectedCountries
                            ),
                            filterMetrics(
                              dataAuthMethods,
                              settings,
                              "Authentication",
                              "Geography",
                              "Transaction share of authentication methods",
                              undefined,
                              selectedCountries
                            ),
                            "name",
                            "value"
                          )}
                          palette={_variablesSix}
                          tooltipMeasureUnit="%"
                        />
                        <div
                          style={{
                            marginTop: "",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          {dataAuthMethodsCountries.length > 0 && (
                            <McDonutSideLegend
                              data={compareAndAddDummy(
                                filterMetrics(
                                  dataAuthMethodsCountries,
                                  settings,
                                  "Authentication",
                                  "Geography",
                                  "Transaction share of authentication methods",
                                  undefined,
                                  selectedCountries
                                ),
                                filterMetrics(
                                  dataAuthMethods,
                                  settings,
                                  "Authentication",
                                  "Geography",
                                  "Transaction share of authentication methods",
                                  undefined,
                                  selectedCountries
                                ),
                                "name",
                                "value"
                              )}
                              palette={_variablesSix}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {authRateTop5Viz && (
                  <div
                    className="mt-4"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <SmallTitleFlex
                      title={
                        "Authentication Rate for top 5 authentication methods"
                      }
                      tooltip={
                        "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                        "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                      }
                    />
                    <McProgressChartBlock
                      data={
                        selectedCountries.length !== 0
                          ? compareAndAddDummy(
                              filterMetrics(
                                dataTopFiveAuthMethodsCountries,
                                settings,
                                "Authentication",
                                "Geography",
                                "Authentication Rate for top 5 authentication methods",
                                undefined,
                                selectedCountries
                              ),
                              filterMetrics(
                                dataTopFiveAuthMethods,
                                settings,
                                "Authentication",
                                "Geography",
                                "Authentication Rate for top 5 authentication methods",
                                undefined,
                                selectedCountries
                              ),
                              "name",
                              "value"
                            )
                          : padArray(
                              filterMetrics(
                                dataTopFiveAuthMethodsCountries,
                                settings,
                                "Authentication",
                                "Geography",
                                "Authentication Rate for top 5 authentication methods",
                                undefined,
                                selectedCountries
                              ),
                              filterMetrics(
                                dataTopFiveAuthMethods,
                                settings,
                                "Authentication",
                                "Geography",
                                "Authentication Rate for top 5 authentication methods",
                                undefined,
                                selectedCountries
                              )
                            )
                      }
                      palette={_variablesSix}
                    />
                    {dataChallengeCodeCountries.length > 0 ? (
                      <Button
                        text="Auth method performance by challenge code"
                        style={{
                          fontSize: "12pt",
                          padding: "0.5rem",
                          marginTop: "2rem",
                          width: "15rem",
                          //marginLeft:"6rem",
                        }}
                        dataBsToggle="modal"
                        dataBsTarget={"authMethodModalCountries"}
                      />
                    ) : (
                      <div
                        style={{
                          fontSize: "12pt",
                          padding: "0.5rem",
                          marginTop: "2rem",
                          width: "15rem",
                          height: "4.15rem",
                          //marginLeft:"6rem",
                        }}
                      ></div>
                    )}
                  </div>
                )}
              </>
            )}

            {dashboardVersion2 && (
              <Modal
                dataBsTarget="authMethodModalCountries"
                title={<b>Top 5 Authentication Methods by Challenge Code</b>}
                modalBody={
                  <div
                    style={{
                      width: "100%",
                      height: "29rem",
                      display: "flex",
                      gap: "8rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                      }}
                    >
                      <McDonutChartSingle
                        data={dataChallengeCodeCountries}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Failed due to ACS Timeout</b>
                        <McDonutSideLegend
                          data={dataChallengeCodeCountries.map((el) => {
                            return { name: el.label, value: el.value };
                          })}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <TableAG
                      colDefs={colsDef}
                      rowData={filterMetrics(
                        dataChallengeCodeForAuthMethodCountries,
                        settings,
                        "Authentication",
                        "Geography",
                        "Authentication Rate for top 5 authentication methods",
                        "auth_method",
                        selectedCountries
                      ).slice(0, 5)}
                    />
                  </div>
                }
              >
                <Download
                  section="AutheGeography"
                  chartName="Authentication Methods by Challenge Code"
                  data={filterMetrics(
                    dataChallengeCodeForAuthMethodCountries,
                    settings,
                    "Authentication",
                    "Geography",
                    "Authentication Rate for top 5 authentication methods",
                    "auth_method",
                    selectedCountries
                  )}
                  graphType="pie"
                  label={"Authentication Method"}
                  country={selectedCountries}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[
                    { field: "Percent 05", value: "percent_zerofive" },
                    { field: "Percent 04", value: "percent_zerofour" },
                    { field: "Total", value: "total_trx" },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </Modal>
            )}
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default AuthenticationGeography;
