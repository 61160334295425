import { countries } from "countries-list";
import { _variablesTwoOranges } from "../styles/_variables";

const monthAbbreviations = {
  JAN: "01",
  FEB: "02",
  MAR: "03",
  APR: "04",
  MAY: "05",
  JUN: "06",
  JUL: "07",
  AUG: "08",
  SEP: "09",
  OCT: "10",
  NOV: "11",
  DEC: "12",
};

// generate last 12 months labels starting from last available
const generateLastTwelveMonths = (endDateString) => {
  const endDate = new Date(endDateString);
  const startDate = new Date(
    endDate.getFullYear() - 1,
    endDate.getMonth(),
    endDate.getDate()
  );
  const dateLabels = [];

  for (let index = 0; index < 12; index++) {
    startDate.setMonth(startDate.getMonth() + 1);
    const monthString = startDate.toLocaleDateString("en-US", {
      month: "short",
      year: "2-digit",
    });
    dateLabels.push(monthString.toUpperCase());
  }

  return dateLabels;
};

//generate last n months labels starting from last available
const generateLastMonths = (endDateString, numberOfMonths) => {
  const endDate = new Date(endDateString);
  const startDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth() - numberOfMonths + 1,
    endDate.getDate()
  );
  const dateLabels = [];

  for (let index = 0; index < numberOfMonths; index++) {
    const monthString = startDate.toLocaleDateString("en-US", {
      month: "short",
      year: "2-digit",
    });
    dateLabels.push(monthString.toUpperCase());
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return dateLabels;
};

// convert month label to a format ok for the apis
const convertMonthLabelForApi = (dateLabel) => {
  if (dateLabel) {
    const [monthAbbr, year] = dateLabel.split(" ");
    const month = monthAbbreviations[monthAbbr];
    const dateFormatted = `20${year}${month}`;

    return dateFormatted;
  } else {
    return null;
  }
};

// round number to one decimal place + format to locale us + force one decimal place
const formatNumberFull = (number) => {
  return typeof number === "number"
    ? (Math.round(number * 10) / 10).toLocaleString("en-US", {
        minimumFractionDigits: 1,
      })
    : null;
};

// round number to one decimal place + format to locale us
const formatNumberRoundLocale = (number) => {
  return typeof number === "number"
    ? (Math.round(number * 10) / 10).toLocaleString("en-US", {
        minimumFractionDigits: 0,
      })
    : 0;
};

// round number to one decimal place
const formatNumberRound = (number) => {
  return typeof number === "number" ? Math.round(number * 10) / 10 : 0;
};

// format number to locale us + optional force one decimal place
const formatNumberLocale = (number, forceDigit = false) => {
  return number.toLocaleString("en-US", {
    minimumFractionDigits: forceDigit === true ? 1 : 0, // I have to check if === true unfortunately
  });
};

// string to title case
const titleCase = (str) => {
  if (str.toLowerCase() === "uk") {
    return "United Kingdom";
  }

  let words = str.toLowerCase().split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return str === "USA" ? str : words.join(" ");
};

// format error to display it in an alert
const formatErrorForAlert = (err) => {
  let errFormatted =
    "Something went wrong! Please contact your system administrator";

  // for debugging purposes
  /* let errFormatted =
    err.code +
    ": " +
    (err.response && err.response.data.detail
      ? Array.isArray(err.response.data.detail)
        ? err.response.data.detail.map((obj) => obj.msg).join(", ")
        : err.response.data.detail
      : err.message); */

  return errFormatted;
};

// convert country name to country code
const convertCountryToCode = (countryName) => {
  if (countryName.toLowerCase().includes("germany")) {
    return "DE";
  }

  for (let key in countries) {
    if (countries[key].name.toLowerCase() === countryName.toLowerCase()) {
      return key;
    }
  }

  return countryName.substring(0, 2).toUpperCase();
};

// find max in an array of object between any property
const findMax = (data) => {
  if (data.length === 0) return;
  let propertyNames = Object.keys(data[0]);
  let maxValue = 0;

  propertyNames.forEach((propertyName) => {
    data.forEach((item) => {
      if (item[propertyName] > maxValue) {
        maxValue = item[propertyName];
      }
    });
  });

  return maxValue;
};

// custom tooltip for chart
const customTooltipLayout = (
  active,
  payload,
  label,
  tooltipMeasureUnit,
  additionalData = undefined,
  autoFormatData = true
) => {
  return (
    <div className="custom-tooltip-container p-2">
      <div className="mb-1">{label}</div>
      {payload.map((p, index) => {
        return (
          <div key={index + p.value + Math.random()}>
            <div
              style={{
                color:
                  p.payload.avg === false
                    ? _variablesTwoOranges.primaryOrangeLight
                    : p.color,
              }}
              className="custom-tooltip-text"
            >
              {additionalData && additionalData.length > 0 ? (
                <div className="mb-1">
                  <b>{p.name}: </b>
                  {`${formatNumberFull(
                    additionalData[p.payload.index][p.name]
                  )}% (${formatNumberFull(p.value)}${
                    p.name.includes("rate") && tooltipMeasureUnit !== " bps"
                      ? "%"
                      : tooltipMeasureUnit
                  })`}
                </div>
              ) : (
                <>
                  {p.name}:{" "}
                  {`${
                    autoFormatData || p.name.includes("rate")
                      ? formatNumberFull(p.value)
                      : p.value
                  }`}
                  {`${
                    p.name.includes("rate") && tooltipMeasureUnit !== " bps"
                      ? "%"
                      : tooltipMeasureUnit
                  }`}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

// sort ticket ranges and fill in missing values
const sortTicketRanges = (labels, currencySymbol, column) => {
  var orderedLabels = [
    `0 ${currencySymbol}`,
    `0.01-30 ${currencySymbol}`,
    `30.01-100 ${currencySymbol}`,
    `100.01-250 ${currencySymbol}`,
    `250.01-500 ${currencySymbol}`,
    `+500 ${currencySymbol}`,
  ];

  // add missing values
  if (column === "left") {
    orderedLabels.forEach((label) => {
      if (
        !labels.find((el) =>
          el.label ? el.label === label : el.name === label
        )
      ) {
        labels.push({ label: label, name: label, value: 0.0 });
      }
    });
  } else {
    orderedLabels.forEach((label) => {
      if (
        !labels.find((el) =>
          el.label ? el.label === label : el.name === label
        )
      ) {
        labels.push({ label: "", name: null, value: null });
      }
    });
  }

  labels.forEach((el) => {
    const labelIndex = orderedLabels.indexOf(el.label || el.name);
    if (labelIndex !== -1) {
      el.index = labelIndex;
    }
  });

  // sort labels
  labels.sort(function (a, b) {
    var labelA = a.label ? a.label : a.name;
    var labelB = b.label ? b.label : b.name;
    var indexA = orderedLabels.indexOf(labelA);
    var indexB = orderedLabels.indexOf(labelB);
    return indexA - indexB;
  });

  return labels;
};

const fill3DSMissingData = (labels) => {
  var orderedLabels = ["3DS", "Non 3DS"];

  // add missing values
  orderedLabels.forEach((label) => {
    if (
      !labels.find((el) => (el.label ? el.label === label : el.name === label))
    ) {
      labels.push({ label: label, name: label, value: 0.0 });
    }
  });
  return labels;
};

// sort subchannels and fill in missing values
const sortSubchannelsList = (subchannels) => {
  var orderedLabels = ["Ecommerce", "MOTO", "Other", "Recurring"];

  // add missing values
  /* orderedLabels.forEach((label) => {
    if (
      !subchannels.find((el) =>
        el.label ? el.label === label : el.name === label
      )
    ) {
      subchannels.push({ name: label, value: 0 });
    }
  }); */
  subchannels.forEach((el) => {
    const labelIndex = orderedLabels.indexOf(el.label || el.name);
    if (labelIndex !== -1) {
      el.index = labelIndex;
    }
  });

  // sort labels
  subchannels.sort(function (a, b) {
    if (a.index < b.index) {
      return -1;
    } else if (a.index > b.index) {
      return 1;
    } else {
      return 0;
    }
  });

  return subchannels;
};

// assemble body for apis
const assembleBody = (
  merchantName,
  startDate,
  endDate,
  acquirer = null,
  isVolume = false,
  product_type = "",
  currency
) => {
  let body = {
    merchant_name: merchantName,
    start_date: convertMonthLabelForApi(startDate),
    end_date: convertMonthLabelForApi(endDate),
    is_volume: isVolume,
    product_type: product_type,
    currency: currency,
  };

  if (acquirer || acquirer === "") {
    body.acquirer_name = acquirer;
  }

  return body;
};

const assembleBodyWithProductType = (
  merchantName,
  startDate,
  endDate,
  acquirer = null,
  product_type = "",
  isVolume = false
) => {
  let body = {
    merchant_name: merchantName,
    start_date: convertMonthLabelForApi(startDate),
    end_date: convertMonthLabelForApi(endDate),
    product_type: product_type,
    is_volume: isVolume,
  };

  if (acquirer || acquirer === "") {
    body.acquirer_name = acquirer;
  }

  return body;
};

const assembleBodyFinal = (
  merchantName,
  startDate,
  endDate,
  acquirer = null,
  product_type = "",
  isVolume = false
) => {
  let body = {
    merchant_name: merchantName,
    start_date: convertMonthLabelForApi(startDate),
    end_date: convertMonthLabelForApi(endDate),
    product_type: product_type,
    is_volume: isVolume,
  };

  if (acquirer || acquirer === "") {
    body.acquirer_name = acquirer;
  }

  return body;
};

function compareAndAddDummy(
  array1,
  array2,
  label,
  param1 = null,
  param2 = null
) {
  const param1Name = param1 ? param1 : "trx_share";
  const param2Name = param2 ? param2 : "approval_rate";
  const dummyValue = { [param1Name]: 0.0, [param2Name]: 0.0 };
  const labelSet1 = new Set(array1.map((item) => item[label]));
  const result = [];

  array2.forEach((item) => {
    if (labelSet1.has(item[label])) {
      result.push(array1.find((x) => x[label] === item[label]));
    } else {
      result.push({ [label]: item[label], ...dummyValue });
    }
  });

  return result;
}

function mergeData(data1, data2, fields1, fields2, compareField) {
  let mergedList = [];
  data1.forEach((item1) => {
    data2.forEach((item2) => {
      if (item2[compareField]?.localeCompare(item1[compareField]) === 0) {
        let mergedItem = { [compareField]: item1[compareField] };
        fields1.forEach(({ source, target }) => {
          mergedItem[target] = item1[source];
        });
        fields2.forEach(({ source, target }) => {
          mergedItem[target] = item2[source];
        });
        mergedList.push(mergedItem);
      }
    });
  });
  return mergedList;
}

const padArray = (arrayToDisplay, arrayToCompare) => {
  let paddedArrayToDisplay = [...arrayToDisplay];

  // Mappa per tenere traccia degli indici in arrayToCompare
  const compareIndexMap = new Map();
  arrayToCompare.forEach((item, index) => {
    compareIndexMap.set(item.name, index);
  });

  // Aggiorna gli indici degli elementi corrispondenti in arrayToDisplay
  paddedArrayToDisplay = paddedArrayToDisplay.map((item, index) => {
    if (compareIndexMap.has(item.name)) {
      return { ...item, index: compareIndexMap.get(item.name) };
    }
    return { ...item, index };
  });

  // Aggiungi elementi nascosti se arrayToCompare è più lungo di arrayToDisplay
  if (arrayToCompare.length > arrayToDisplay.length) {
    for (
      let index = arrayToDisplay.length;
      index < arrayToCompare.length;
      index++
    ) {
      paddedArrayToDisplay.push({
        name: "",
        value: 0,
        visibility: "hidden",
        index: index,
      });
    }
  }
  return paddedArrayToDisplay;
};

const padArrayProgramAndSubchannel = (arrayToDisplay, arrayToCompare) => {
  let paddedArrayToDisplay = [...arrayToDisplay];

  if (arrayToCompare.length > arrayToDisplay.length) {
    for (
      let index = 0;
      index < arrayToCompare.length - arrayToDisplay.length;
      index++
    ) {
      paddedArrayToDisplay.push({
        subchannel: arrayToCompare[index].subchannel,
        rate_3ds: 0,
        rate_non_3ds: 0,
      });
    }
  }

  return paddedArrayToDisplay;
};

const padArrayProgram = (arrayToDisplay, arrayToCompare) => {
  let paddedArrayToDisplay = [...arrayToDisplay];

  if (arrayToCompare.length > arrayToDisplay.length) {
    for (
      let index = 0;
      index < arrayToCompare.length - arrayToDisplay.length;
      index++
    ) {
      paddedArrayToDisplay.push({
        label: arrayToCompare[index].label,
        value: 0,
      });
    }
  }

  return paddedArrayToDisplay;
};

const declineReasons = {
  "Capture Card":
    "Blocked/cancelled cards due to confirmed previous fraud (excluding lost or stolen cases), and account-level fraudulent activities like identity theft or ATO-account takeovers.",
  "Declined budget limit":
    "Applied when the account surpasses its credit limit or lacks sufficient available balance, including temporary delinquency situations during the early delinquency period.",
  "Do not Honor":
    "Reserved for non-technical declines to be used by issuers when no other reason applies.",
  "Expired Card":
    "Applied for transactions with cards expired (dates in the past); if a new card with the same PAN but updated expiry date is available; for mismatched dates with issuer's database.",
  "Insufficient funds":
    "Applied when the account reaches or exceeds its credit limit, or when the available balance is insufficient, including temporary delinquency situations during the early delinquency period.",
  "Invalid Card Number":
    "Used for transactions where the account number (PAN) is invalid, not issued by the issuer, or closed, and for discrepancies in the card expiration date.",
  "Invalid PIN":
    "To be used in case of PIN required transactions with failure in PIN validation (wrong PIN) or missing PIN.",
  "Invalid Transaction":
    "Applicable scenarios encompass restrictions on instalment numbers, transaction types disallowed by issuer decisions (e.g., contactless), and declines for fallback transactions.",
  Others: "Other unspecified reasons.",
  "Restricted Card":
    "Usage scenarios include card-level restrictions, deceased cardholder, and permanent blocking due to high delinquency or account cancellation by issuer or cardholder.",
  "SCA decline":
    "When strong customer authentication (SCA) is declined, such as PIN entry.",
  "Transaction not permitted by issuer/cardholder":
    "Various transaction restrictions: product, BIN, internal policy, restricted countries, cross-border usage on domestic cards, lack of customer notification, inactivated cards, and specific response codes for fraud detection.",
};

const ukMerchants = [
  "PAYPAL",
  "MORRISONS",
  "EASYJET",
  "PRIMA",
  "SKY",
  "LFC",
  "GOOGLE",
  "TJC",
  "RYANAIR",
];

export {
  generateLastTwelveMonths,
  generateLastMonths,
  convertMonthLabelForApi,
  formatNumberFull,
  formatNumberRound,
  formatNumberRoundLocale,
  formatNumberLocale,
  formatErrorForAlert,
  titleCase,
  convertCountryToCode,
  findMax,
  customTooltipLayout,
  sortTicketRanges,
  sortSubchannelsList,
  assembleBody,
  assembleBodyWithProductType,
  assembleBodyFinal,
  fill3DSMissingData,
  declineReasons,
  compareAndAddDummy,
  mergeData,
  padArray,
  padArrayProgram,
  padArrayProgramAndSubchannel,
  ukMerchants,
};

export const filterVisibility = (
  type,
  modules,
  module_name,
  tab_name,
  chart_name,
  selectedCountry
) => {
  if (!modules) {
    return false;
  }
  for (const module of modules) {
    if (type === "module" && module.value === module_name) {
      return module.isVisible;
    }
    if (type === "tab" && module.value === module_name) {
      for (const tab of module.tabs) {
        if (tab.value === tab_name) {
          return tab.isVisible;
        }
      }
    }
    if (type === "chart" && module.value === module_name) {
      for (const tab of module.tabs) {
        if (tab.value === tab_name) {
          for (const chart of tab.charts) {
            if (chart.value === chart_name) {
              if (
                selectedCountry &&
                selectedCountry.length > 0 &&
                selectedCountry !== "0"
              ) {
                if (Array.isArray(selectedCountry)) {
                  // Handle case where selectedCountry is an array
                  for (const country of selectedCountry) {
                    const countryValue = country.value;
                    if (
                      chart.visibilityRules.countries[countryValue] &&
                      !chart.visibilityRules.countries[countryValue].isVisible
                    ) {
                      return false;
                    }
                  }
                  return true;
                } else {
                  // Handle case where selectedCountry is a string
                  if (!chart.visibilityRules.countries[selectedCountry]) {
                    return true;
                  } else {
                    return chart.visibilityRules.countries[selectedCountry]
                      .isVisible;
                  }
                }
              } else {
                return chart.isVisible;
              }
            }
          }
        }
      }
    }
  }
  return false;
};

export const filterMetrics = (
  data,
  modules,
  module_name,
  tab_name,
  chart_name,
  key_name,
  selectedCountry
) => {
  if (!key_name) {
    key_name = "name";
  }
  if (!modules) {
    return data;
  }

  for (const module of modules) {
    if (module.value === module_name) {
      for (const tab of module.tabs) {
        if (tab.value === tab_name) {
          for (const chart of tab.charts) {
            if (chart.value === chart_name) {
              if (
                !chart.visibilityRules ||
                !chart.visibilityRules.globalMetrics
              ) {
                return data;
              }

              if (selectedCountry) {
                const countries = Array.isArray(selectedCountry)
                  ? selectedCountry.map((c) => c.value)
                  : [selectedCountry];

                return data?.filter((item) => {
                  const isHiddenInAnyCountry = countries.some((country) => {
                    const countryRules =
                      chart.visibilityRules.countries[country];
                    return countryRules?.hiddenMetrics.includes(item[key_name]);
                  });

                  if (isHiddenInAnyCountry) {
                    return false;
                  }

                  const metric =
                    chart.visibilityRules.globalMetrics[item[key_name]];
                  return metric ? metric.isVisible : true;
                });
              }

              return data?.filter((item) => {
                const metric =
                  chart.visibilityRules.globalMetrics[item[key_name]];
                return metric ? metric.isVisible : true;
              });
            }
          }
        }
      }
    }
  }

  return data;
};

//used for metrics for wich the label is the metric itself (object_name is the object containing the metrics)
export const filterMetricsByKeys = (
  data,
  modules,
  module_name,
  tab_name,
  chart_name,
  object_name
) => {
  if (!modules) {
    return data;
  }

  for (const module of modules) {
    if (module.value === module_name) {
      for (const tab of module.tabs) {
        if (tab.value === tab_name) {
          for (const chart of tab.charts) {
            if (chart.value === chart_name) {
              if (
                !chart.visibilityRules ||
                !chart.visibilityRules.globalMetrics
              ) {
                return data;
              }

              return data.map((item) => {
                const filteredItem = { ...item };
                if (filteredItem[object_name]) {
                  filteredItem[object_name] = filteredItem[object_name].filter(
                    (reason) => {
                      const metric =
                        chart.visibilityRules.globalMetrics[reason.label];
                      return metric ? metric.isVisible : true;
                    }
                  );
                }
                return filteredItem;
              });
            }
          }
        }
      }
    }
  }

  return data;
};
